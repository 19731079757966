import React, {useEffect, useState} from 'react';
import {useContextData} from "../../store/ContextData";
import { Link } from "react-router-dom";

import DA from "../../static/svg/dashboard.active.svg";
import DI from "../../static/svg/dashboard.inactive.svg";
import TA from "../../static/svg/template.active.svg";
import TI from "../../static/svg/template.inactive.svg";
import DRA from "../../static/images/drafts.active.png";
import DRI from "../../static/svg/drafts.inactive.svg";
import LI from "../../static/svg/logs.inactive.svg";
import LA from "../../static/images/logs.active.png";
import OI from "../../static/svg/output.inactive.svg";
import OA from "../../static/images/output.active.png";
import IA from "../../static/images/image.active.png";
import II from "../../static/images/image.inactive.png";
import PA from "../../static/images/paragraph.active.png";
import PI from "../../static/images/paragraph.inactive.png";
import UA from "../../static/svg/user.active.svg";
import UI from "../../static/svg/user.inactive.svg";
import INA from "../../static/svg/integrations.active.svg";
import INI from "../../static/svg/integrations.inactive.svg";
import ATA from "../../static/svg/automation.active.svg";
import ATI from "../../static/svg/automation.inactive.svg";

require("../../static/css/side-nav.css");

const SideNav = (props) => {
    const {index:activeIndex} = props;
    const [menu, setMenu] = useState([]);
    const { email, userRole } = useContextData();
    
    useEffect(() => {
        const menuArray = [{
            name: 'dashboard',
            path: '/',
            image: {
                active: DA,
                passive: DI
            }
        },{
            name: 'template',
            path: '/template/',
            image: {
                active: TA,
                passive: TI
            }
        },{
            name: 'images',
            path: '/images/',
            image: {
                active: IA,
                passive: II
            },
        },{
            name: 'paragraph',
            path: '/paragraph/',
            image: {
                active: PA,
                passive: PI
            }
        },{
            name: 'drafts',
            path: '/drafts/',
            image: {
                active: DRA,
                passive: DRI
            }
        },{
            name: 'output',
            path: '/output/',
            image: {
                active: OA,
                passive: OI
            }
        },{
            name: 'logs',
            path: '/logs/',
            image: {
                active: LA,
                passive: LI
            }
        }, {
            name: 'integrations',
            path: '/integrations/',
            image: {
                active: INA,
                passive: INI
            }
        }, {
            name: 'automation',
            path: '/automation/',
            image: {
                active: ATA,
                passive: ATI
            }
        }];

        if(userRole === 1) menuArray.splice(7, 0, {
            name: 'users',
            path: '/users/',
            image: {
                active: UA,
                passive: UI
            }
        });
        
        setMenu(menuArray);
    }, [userRole, email]);

    return (
        <section className="side-nav">
            <ul>
                {
                    menu.map((el, index) => {
                        const isActive = (index === activeIndex - 1);
                        return (
                            <li
                                className={`${el.name}`+ (isActive ? " active" : "")}
                                key={index}
                            >
                                <Link to={el.path}>
                                    <div className="flex-container vertical-middle">
                                        <div className="flexbox icon">
                                            <img
                                                src={isActive ? el.image.active : el.image.passive}
                                                alt="nav icon"
                                            />
                                        </div>
                                        <div className="flexbox text">
                                            <span className="text-uppercase">{el.name}</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )
}

export default SideNav;
