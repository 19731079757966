import React, {Component} from 'react';
import Select from 'react-select'

class MapFields extends Component{
    constructor(props){
        super(props);
        this.state = {
            markers: [],
            headers: [],
            mapObject: {}
        }
    }

    setMapValue(e, k){
        var mapObject = JSON.parse(JSON.stringify(this.state.mapObject));
        mapObject[k] = e.value;
        this.setState({
            mapObject: mapObject
        }, () => {
            this.props.callback(this.props.skey, this.state.mapObject);
        })
    }

    componentDidMount(){
        // console.log(this.props.data);
        var markers = JSON.parse(JSON.stringify(this.props.markers)),
            tempArray = [],
            mapObject = {},
            headers = [],
            i = 0;

        Object.keys(markers).forEach(key => {
            // if(markers[key] === true){ 
                tempArray.push({
                    key: key
                });

                if(this.props.data && this.props.data[key]) mapObject[key] = this.props.data[key];
                else mapObject[key] = "";
            // }
        });

        for(; i<this.props.headers.length; i++)
            headers.push({value: this.props.headers[i], label: this.props.headers[i].replace(/_/g, ' ')})
        
        this.setState({
            markers: tempArray,
            headers: headers,
            mapObject: mapObject
        }, () => {
            this.props.callback(this.props.skey, this.state.mapObject);
        })
    }

    render(){
        return (<div>
            <div className="form-container">
                <div className="form-group has-field">
                    <label htmlFor="map">Map fields in the template with the fields in the uploaded data</label>
                    <table className="table map">
                        <tbody>
                            {
                                this.state.markers.map((d, i) => (
                                    <tr key={i}>
                                        <td>{`${i + 1}. ${d.key.replace(/_/g, ' ')}`}</td>
                                        <td>
                                            <Select className="input-select" isSearchable={true} name={d.key} options={this.state.headers} onChange={(e) => this.setMapValue(e, d.key)} value={this.state.headers.filter(option => option.value === this.state.mapObject[d.key])}/>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="footer text-right">
                <button className="btn" onClick={() => this.props.callback('stepNumber', 0)}>Back</button>
                <button className="btn moral" onClick={() => this.props.callback('stepNumber', 2)}>Next Step</button>
            </div>
        </div>);
    }
}

export default MapFields;