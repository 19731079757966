import React, {Fragment, useState, useEffect} from 'react';
import { config } from '../../constant';
import DocusignDynamicField from "../docusign-dynamic-field";


const DocuSignDistribution = (props) => {
  const {skey, tab, callback, data={}, disable, headers, availPlatform} = props;
  const {DOCUMENT_SIGN_INTEGRATIONS} = config;
  const hasDocusignPlatform = availPlatform.some(
    el => DOCUMENT_SIGN_INTEGRATIONS.find(csi => csi.path === el.platform)
  );

  const documentFields = headers.map(value => {
    return {value, label: value.replace(/_/g, ' ')}
  });
  
  const {
    signers:psign=[{name: "", email: ""}], subject:ps="", cc:pcc=[{name: "", email: ""}]
  } = data;
  const [cc, setCc] = useState(pcc);
  const [subject, setSubject] = useState(ps);
  const [signers, setSigners] = useState(psign);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if(!subject){
      setIsFormValid(false);
      return;
    }

    // Check whether all name and email is set for signers
    const signerSetStatus = signers.every(el => el.name && el.email && el.name !== el.email);
    if(!signerSetStatus){
      setIsFormValid(false);
      return;
    }

    // Checking whether selected all signers name and email is unique or not
    let nonUniqueSignerStatus = false;
    for(let i=0; i<signers.length; i++){
      const signer = signers[i];
      for(let j=i+1; j<signers.length; j++){
        const el = signers[j];
        if(
          el.name === signer.name || el.name === signer.email ||
          el.email === signer.name || el.email === signer.email
        ){
          nonUniqueSignerStatus = true;
          break
        }
      }
      if(nonUniqueSignerStatus){
        break;
      }
    }
    if(nonUniqueSignerStatus){
      setIsFormValid(false);
      return;
    }

    // Checking if CC is set by user or not
    const hasCCStatus = cc.some(el => el.email || el.name)
    if(hasCCStatus){
      // Check whether all name and email is set for cc
      const ccSetStatus = cc.every(el => el.name && el.email && el.name !== el.email);
      if(!ccSetStatus){
        setIsFormValid(false);
        return;
      }

      // Check signer columns should not present as CC
      const nonUniqueCCStatus = cc.some(cc => signers.some(signer => (
        cc.name === signer.name || cc.name === signer.email ||
        cc.email === signer.name || cc.email === signer.email
      )));
      if(nonUniqueCCStatus){
        setIsFormValid(false);
        return;
      }
    }

    setIsFormValid(true);
  }, [subject, signers, cc]);

  useEffect(() => {
    setTimeout(() => {
      disable(!isFormValid);
      callback(skey, {
        tab,
        subject,
        signers,
        cc
      });
    });
  }, [isFormValid, subject, signers, cc, skey, disable, tab, callback]);

  return (
    <div className="tab-body docusign">
      {hasDocusignPlatform && 
        <Fragment>
          <div className="form-group has-field">
            <label htmlFor="email-subject">Email subject<span>*</span></label>
            <input
              type="text"
              id="email-subject"
              className="input-field"
              placeholder="Write down the subject of the email"
              autoComplete="off"
              onChange={(e) => setSubject(e.target.value)}
              required="required"
              value={subject}
            />
          </div>

          <DocusignDynamicField
            rows={signers}
            setRows={setSigners}
            selectOptions={documentFields}
            heading={"Select signer's name and email column"}
            buttonText={"Add another Signer column"}
            id={"signer"}
          />

          <DocusignDynamicField
            rows={cc}
            setRows={setCc}
            selectOptions={documentFields}
            heading={"Select CC's name and email column"}
            buttonText={"Add another CC column"}
            id={"cc"}
            requiredFields={false}
          />
        </Fragment>
      }
      {!hasDocusignPlatform && 
        <div className="load-anchor text-center">
          <div className="loading vertical-middle">
            <a href="/integrations/" className="btn-link center">Integrate Document Sign Platform</a>
          </div>
        </div>
      }
    </div>
  )
};

export default DocuSignDistribution;