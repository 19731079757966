import React, {Fragment, useEffect} from 'react';
import { config } from '../../constant';

import OneDrive from "./onedrive";
import Sharepoint from "./sharepoint";

const CloudStorageDistribution = (props) => {
  const {callback, skey, disable, tab, data = {}, availPlatform} = props;
  const {CLOUD_STORAGE_INTEGRATIONS} = config;
  const hasCloudStoragePlatform = availPlatform.some(
    el => CLOUD_STORAGE_INTEGRATIONS.find(csi => csi.path === el.platform)
  );

  const {platform:propPlatform="", data:propData=""} = data;
  const setCloudStorageData = (platform, data) => {
    disable(false);
    callback(skey, {
      tab,
      platform,
      data
    });
  };

  useEffect(() => {
    setTimeout(() => {
      disable(true);
    });
  }, [disable]);
  
  return (
    <div className="tab-body cloud-storage">
      {
        availPlatform.map((el, index) => {
          const {platform} = el;
          const intl = CLOUD_STORAGE_INTEGRATIONS.find(intelm => intelm.path === platform);
          return (
            <Fragment key={platform + index}>
              {platform === 'onedrive' && 
                <OneDrive
                  callback={setCloudStorageData}
                  intl={intl}
                  data={{
                    platform: propPlatform, 
                    data: propData
                  }}
                  fileSelect={false}
                />
              }
              {platform === 'sharepoint' && 
                <Sharepoint
                  callback={setCloudStorageData}
                  intl={intl}
                  data={{
                    platform: propPlatform, 
                    data: propData
                  }}
                  fileSelect={false}
                />
              }
            </Fragment>
          )
        })
      }
      <div className="load-anchor text-center">
        {!hasCloudStoragePlatform && 
          <div className="loading vertical-middle">
            <a href="/integrations/" className="btn-link center">Integrate Cloud storage</a>
          </div>
        }
      </div>
    </div>
  );
};

export default CloudStorageDistribution;