import React, {Component} from 'react';

class ProgressRing extends Component{
    constructor(props){
        super(props);
        this.state = {
            radius: this.props.radius, 
            stroke: this.props.stroke, 
            progress: this.props.progress
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            radius: nextProps.radius, 
            stroke: nextProps.stroke, 
            progress: nextProps.progress
        });
    }

    render(){
        var square = this.state.radius * 2,
            normalizedRadius = this.state.radius - this.state.stroke * 2,
            circumference = normalizedRadius * 2 * Math.PI, 
            strokeDashoffset = circumference - this.state.progress / 100 * circumference;

        return (
            <div style={{width:square, height:square}} className="progress-ring-cont">
                <svg height={square} width={square}>
                    <circle cx={this.state.radius} cy={this.state.radius} r={normalizedRadius} stroke="#ECECEC" strokeWidth={this.state.stroke} fill="transparent"/>
                    <circle stroke="#33A913"
                        style={ { strokeDashoffset } }
                        strokeWidth={this.state.stroke}
                        strokeDasharray={circumference +' '+ circumference}
                        fill="transparent"
                        r={normalizedRadius}
                        cx={this.state.radius}
                        cy={this.state.radius}
                        className="progress-ring"
                    />
                </svg>
                <span className="text">{Math.round( this.props.progress * 10 ) / 10}%</span>
            </div>
        )
    }
}

export default ProgressRing;
