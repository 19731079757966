import React, {useState, useEffect} from "react";

import {config} from "../constant";
import dltBtn from "../../static/svg/delete.svg";

const InputData = (props) => {
  const {platform, inputFields, onSubmit, close} = props;

  const initFormData = inputFields.reduce((data, input) => {
    const {name} = input;
    data[name] = "";
    return data;
  }, {});
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState(initFormData);

  const {CLOUD_DATA_INTEGRATIONS} = config;
  const platformName = CLOUD_DATA_INTEGRATIONS.find(el => el.path === platform);

  useEffect(() => {
    const status = inputFields.every(field => {
      const {name, required} = field;
      if(!required){
        return true;
      }

      return formData[name] !== "";
    });
    setIsValid(status);
  }, [formData, inputFields]);

  return (
    <div className="full-page-overlay">
      <div className="flex-container full-height vertical-middle">
        <div className="template-uploader relative">
          <div className="modal-body relative">
            <div className="heading">Fill data to integrate {platformName ? platformName.name : platform}</div>
            <div className="close" onClick={close}>
              <img src={dltBtn} alt="close"/>
            </div>

            {
              inputFields.map(el => {
                const {name, label, required} = el;
                return (
                  <div className="form-group has-field" key={name}>
                    <label htmlFor={name}>{label} {required && <span>*</span>}</label>
                    <input 
                      type="text" 
                      id={name} 
                      name={name}
                      className="input-field" 
                      placeholder={`Write down "${label}"`}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData(fData => (
                          {...fData, [name]: value}
                        ))
                      }} 
                    />
                  </div>
                )
              })
            }

            <div className="footer text-right">
              <button 
                className="btn green"
                onClick={close}
              >Cancel</button>
              <button
                className="btn moral"
                disabled={!isValid}
                onClick={() => onSubmit(formData)}
              >Authenticate</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default InputData;
