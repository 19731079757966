import React from 'react';
import moment from 'moment';


import folderIcon from "../../../static/svg/folder.svg";
import dltBtn from "../../../static/svg/delete.svg";
import { commonFunction } from '../../common-functions';

const ShowFileFolder = (props) => {
    const {
        fileFolder, folderPath, open, select, close, 
        back, type, loading, fileSelect, acceptedMime
    } = props;
    const supportedFileSelect = acceptedMime ? acceptedMime : [
        "application/pdf", "application/msword", 
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];

    const isSupportedFileFormat = (mimeType) => {
        if(supportedFileSelect.includes(mimeType)){
            return true;
        }

        return supportedFileSelect.some(el => mimeType.includes(el));
    };
    
    return (
        <div className="full-page-overlay">
            <div className="flex-container full-height vertical-middle">
                <div className="template-uploader">
                    <div className="modal-body relative template-uploader">
                        <div className="heading">Select {type === 'sharepoint' ? 'Drive' : (
                            fileSelect ? "File" : 'Folder'
                        )}</div>
                        <div
                            className="close"
                            onClick={() => close()}
                            disabled={loading}
                        >
                            <img src={dltBtn} alt="close"/>    
                        </div>
                        <ul className="file-folder-list">
                        {
                            fileFolder.map((el, index) => {
                                const {name, file, folder, lastModifiedDateTime} = el;
                                const {mimeType} = file || {};
                                return (
                                    <li className="option-selector below-space" key={name + index}>
                                        <img
                                            src={file ? commonFunction.getIcon(name) : folderIcon}
                                            className={`icon ${file ? 'file' : 'folder'}`}
                                            alt="icon"
                                        />
                                        <div className="text">
                                            <div className="title">{name}</div>
                                            <div className="btn-desc">
                                                {moment(lastModifiedDateTime).format('Do MMM, YYYY')}
                                            </div>
                                        </div>
                                        {folder && <div className="flexbox btn-cont">
                                            {!fileSelect && 
                                                <button 
                                                    disabled={loading}
                                                    className="btn"
                                                    onClick={() => select(el)}
                                                >Select</button>
                                            }
                                            {(type !== 'sharepoint' || fileSelect) && 
                                                <button 
                                                    disabled={loading}
                                                    className="btn moral"
                                                    onClick={() => open(el)}
                                                >Open</button>
                                            }
                                        </div>}
                                        {file && isSupportedFileFormat(mimeType) && fileSelect && 
                                            <button 
                                                disabled={loading}
                                                className="btn"
                                                onClick={() => select(el)}
                                            >Select</button>
                                        }
                                    </li>
                                );
                            })
                        }
                        {
                            fileFolder.length === 0 && <li className="option-selector">
                                {type === 'sharepoint' && `Please create a drive in ${type} to upload.`}
                                {type === 'onedrive' && 'Please create a folder or select this base folder below.'}
                            </li>
                        }
                        </ul>
                        {type !== 'sharepoint' && 
                            <div className="bottom-btn-container">
                                {folderPath && 
                                    <button 
                                        className="btn" 
                                        onClick={back}
                                        disabled={loading}
                                    >Back</button>
                                }
                                {!fileSelect && 
                                    <button 
                                        className="btn moral"
                                        disabled={loading}
                                        onClick={() => select(!folderPath ? "/" : folderPath)}
                                    >
                                        {`Select this ${!folderPath ? 'Base Folder' : `${folderPath} folder`}`}
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ShowFileFolder;