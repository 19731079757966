import React, {useState, Fragment, useEffect} from "react";
import Cron from "react-js-cron";
import Select from "react-select";
import {printFormatValue} from "../../configs/create-edit-automation.config";

const ScheduleGeneration = (props) => {
  const {setStep, stepData, callback, skey} = props;

  const {
    cronValue: pcronValue = "30 5 * * 1,6", schedule = false, ftp = false,
    url = "", port = "", username = "", password = "", path = "/", outputType = "PCL"
  } = stepData;

  const [ftpUrl, setFtpUrl] = useState(url);
  const [showFTP, setShowFTP] = useState(ftp);
  const [ftpPort, setFtpPort] = useState(port);
  const [cronError, setCronError] = useState();
  const [ftpPath, setFtpPath] = useState(path);
  const [cronValue, setCronValue] = useState(pcronValue);
  const [ftpUsername, setFtpUsername] = useState(username);
  const [ftpPassword, setFtpPassword] = useState(password);
  const [disabledNextStep, setDisabledNextStep] = useState(true);
  const [outputFileType, setOutputFileType] = useState(outputType);
  const [scheduleAutomation, setScheduleAutomation] = useState(schedule);

  useEffect(() => {
    if(
      (!scheduleAutomation && !showFTP) ||
      (scheduleAutomation && !showFTP && cronValue && !cronError) ||
      (!scheduleAutomation && showFTP && ftpUrl && ftpPort && ftpUsername && ftpPassword && outputFileType) ||
      (
        scheduleAutomation && showFTP && cronValue && !cronError && ftpUrl && 
        ftpPort && ftpUsername && ftpPassword && outputFileType
      )
    ){
      callback(skey, {
        cronValue,
        ftp: showFTP,
        schedule: scheduleAutomation,
        url: ftpUrl,
        port: ftpPort,
        path: ftpPath,
        username: ftpUsername,
        password: ftpPassword,
        outputType: outputFileType
      });
      setDisabledNextStep(false);
      return;
    }
    
    setDisabledNextStep(true);
  }, [
    scheduleAutomation, showFTP, ftpUrl, ftpPort, ftpUsername, ftpPath,
    ftpPassword, cronValue, cronError, callback, skey, outputFileType
  ]);

  return (
    <Fragment>
      <div className="form-container">
        <p className="desc">
          The scheduler will automate the whole document generation process and 
          the output file will be generated on some interval basis.
        </p>
        <div className="form-group has-field">
          <label className="custom-input" data-type="checkbox">
            Schedule generation
            <input
              type="checkbox"
              name="schedule"
              value="true"
              defaultChecked={scheduleAutomation}
              onClick={() => {
                setScheduleAutomation(!scheduleAutomation);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        {scheduleAutomation &&
          <div className="form-group has-field relative">
            <Cron
              value={cronValue}
              setValue={(data) => {
                setCronValue(data);
              }}
              onError={(error) => {
                setCronError(error)
              }}
            />
          </div>
        }

        <p className="desc">
          If you put down the FTP data, the generated file will be uploaded on your FTP server on printable format.
        </p>
        <div className="form-group has-field">
          <label className="custom-input" data-type="checkbox">
            Upload to FTP
            <input
              type="checkbox"
              name="ftp"
              value="true"
              defaultChecked={showFTP}
              onClick={() => {
                setShowFTP(!showFTP);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        {showFTP && 
          <Fragment>
            <div className="form-group has-field">
              <label htmlFor="ftpurl">FTP URL <span>*</span></label>
              <input 
                type="text" 
                id="ftpurl" 
                className="input-field" 
                placeholder="Write down url for FTP" 
                onChange={(e) => setFtpUrl(e.target.value)} 
                value={ftpUrl} 
              />
            </div>

            <div className="form-group has-field">
              <label htmlFor="ftpport">FTP Port <span>*</span></label>
              <input 
                type="number"
                min="1" 
                max="1000" 
                id="ftpport" 
                className="input-field" 
                placeholder="Write down port for FTP" 
                onChange={(e) => setFtpPort(e.target.value)} 
                value={ftpPort} 
              />
            </div>

            <div className="form-group has-field">
              <label htmlFor="ftpusername">FTP Username <span>*</span></label>
              <input 
                type="text" 
                id="ftpusername" 
                className="input-field" 
                placeholder="Write down username for FTP" 
                onChange={(e) => setFtpUsername(e.target.value)} 
                value={ftpUsername} 
              />
            </div>  

            <div className="form-group has-field">
              <label htmlFor="ftppassword">FTP Password <span>*</span></label>
              <input 
                type="password" 
                id="ftppassword" 
                className="input-field" 
                placeholder="Write down passport for FTP" 
                onChange={(e) => setFtpPassword(e.target.value)} 
                value={ftpPassword} 
              />
            </div>

            <div className="form-group has-field">
              <label htmlFor="ftpurl">FTP upload path <span>*</span></label>
              <input 
                type="text" 
                id="ftpurl" 
                className="input-field" 
                placeholder="Write down path where the generated file will be uploaded" 
                onChange={(e) => setFtpPath(e.target.value)} 
                value={ftpPath} 
              />
            </div>

            <div className="form-group has-field">
              <label htmlFor="template">Print file format <span>*</span></label>
              <Select 
                id="template" 
                className="input-select" 
                value={printFormatValue.filter(option => option.value === outputFileType)} 
                isSearchable={true} 
                name="template" 
                placeholder="Select print file format" 
                options={printFormatValue} 
                onChange={(e) => setOutputFileType(e.value)}
              />
            </div>
          </Fragment>
        }
      </div>
      <div className="footer text-right">
        <button 
          className="btn" 
          onClick={() => setStep(2)}
        >Back</button>
				<button 
					className="btn moral" 
					id="nextDatabaseStep" 
					disabled={disabledNextStep} 
					onClick={() => setStep(4)}
				>Next Step</button>
			</div>
    </Fragment>
  );
};

export default ScheduleGeneration;
