import React, {Component} from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';
import Select from 'react-select';
import { config } from '../../components/constant';
import { commonFunction } from "../../components/common-functions";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import Folder from "../../components/dashboard/folder";
import SideNav from "../../components/dashboard/side-navbar";
import ListItem from "../../components/dashboard/list-item";
import FieldMetaData from "../../components/dashboard/field-metadata";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import ListGridHeading from "../../components/dashboard/list-grid-heading";
import TemplateUploader from "../../components/dashboard/template-uploader";
import DocumentGenerator from "../../components/dashboard/document-generator";

// Importing all images
import add from "../../static/svg/add.svg";
import dltBtn from "../../static/svg/delete.svg";
import merge from "../../static/images/merge-files.png";
import dlt from "../../static/images/delete.png";
// import folder from "../../static/images/folder.png";
import refresh from "../../static/images/refresh.png";

require("../../static/css/dashboard.css");

class Template extends Component{
    constructor(props){
        super(props);
        this.state = {
            role: localStorage.getItem(config.USER_ROLE_KEY),
            searchText: "",
            sortSelection: false,
            page: 0,
            hasMore: true,
            isLoading: false,
            folderID: undefined,
            folders: [],
            formatOptions: [
                {value: 'docx', label: 'DOCX'},
                {value: 'pdf', label: 'PDF'}
            ],
            sortBy: {
                text: 'Upload date - current to old',
                value: 'uploaddate_desc'
            },
            sortChoice: [{
                text: 'Upload date - current to old',
                value: 'uploaddate_desc'
            }, {
                text: 'Upload date - old to current',
                value: 'uploaddate_asc'
            }, {
                text: 'File name - Descending',
                value: 'filename_desc'
            }, {
                text: 'File name - Ascending',
                value: 'filename_asc'
            }],
            viewType: 'list',
            templates: [],
            selectedTemplates: [],
            uploadFile: false,
            generateDoc: false,
            generateMetaData: false,
            formSubmitted: false,
            selectedTemplateId: undefined,
            selectedTemplateName: undefined,
            showDeleteConfirm: false,
            showMergePopup: false,
            showFolderModal: false,
            mergeFileName: "",
            mergeFileFormat: "",
            anchor: undefined
        };
        this.initialTemplateCall = this.initialTemplateCall.bind(this);
        this.setInput = this.setInput.bind(this);
        this.setAction = this.setAction.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.generateDocument = this.generateDocument.bind(this);
        this.generateDraft = this.generateDraft.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.saveMetadata = this.saveMetadata.bind(this);
    }

    setInput(key, data){
        var tempObject = {},
        tempArray = [],
        index = -1,
        flag = false;
        
        switch(key){
            case 'sortBy':
                tempObject.sortSelection = false;
                tempObject[key] = data;

                if(this.state.sortBy.value !== data.value) flag = true;
            break;

            case 'selectAll':
                tempArray = [...this.state.selectedTemplates];
                if(tempArray.length !== this.state.templates.length)
                    for(var i=0; i<this.state.templates.length; i++){
                        index = tempArray.indexOf(this.state.templates[i]._id);
                        if(index === -1) tempArray.push(this.state.templates[i]._id);
                    }
                else tempArray = [];
                tempObject['selectedTemplates'] = tempArray;
            break;

            case 'select':
                tempArray = [...this.state.selectedTemplates];
                index = tempArray.indexOf(data);
                if(index === -1) tempArray.push(data);
                else tempArray.splice(index, 1)

                tempObject['selectedTemplates'] = tempArray;
            break;

            case 'selectedTemplateId':
                if(data instanceof Object){
                    const {elid, name} = data;
                    tempObject[key] = elid;
                    tempObject['selectedTemplateName'] = name;
                }
                else{
                    tempObject[key] = data;
                    tempObject['selectedTemplateName'] = undefined;
                }
                tempObject['generateDoc'] = !this.state.generateDoc;
            break;

            case 'generateMetaData':
                tempObject[key] = data;
                tempObject['selectedTemplateId'] = undefined;
            break;

            case 'folderUpdated':
                tempObject['showFolderModal'] = false;
                this.getAllFolders((data) => {
                    this.initialTemplateCall(undefined, data);
                });
            break;

            case 'folderID':
                tempObject[key] = data;
                tempObject['templates'] = [];
                flag = true;
            break;
            
            default:
                tempObject[key] = data;
            break;
        }

        this.setState(tempObject, () => {
            if(flag) this.initialTemplateCall();
        });
    }

    generateDocument(type, submitData, filename, isPreview, format){
        if(type === 'input'){
            axios.post(`${config.API_URL}api/v1/document/generate/`, submitData)
            .then(() => {  
                Alert.success(`Nice! ${filename} will be generated soon and will be available in output section.`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                this.setInput('selectedTemplateId', undefined);
            });
        }
        else{
            axios.post(`${config.API_URL}api/v1/document/generate/bulk/`, submitData)
            .then(() => { 
                Alert.success(`Nice!${isPreview ? ' Your preview file' : ''} ${filename}.${isPreview ? format : 'zip'} will be generated soon and will be available in output section.`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                if(!isPreview) this.setInput('selectedTemplateId', undefined);
            });
        }
    }

    generateDraft(formData){
        axios.post(`${config.API_URL}api/v1/draft/bulk/`, formData)
        .then(() => {
            Alert.success(`Nice! We have added drafts successfully.`, {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 5000
            });
            this.setInput('selectedTemplateId', undefined);
        })
    }

    downloadJSON(filename){
        axios.get(`${config.API_URL}api/v1/document/markers/file/${this.state.selectedTemplateId}`)
        .then(data => {
            var file = new Blob([JSON.stringify(data.data, null, "    ")], {
                type: 'application/text'
            }),
            url = window.URL.createObjectURL(file),
            a = document.createElement('a'),
            name = filename.split('.');

            name.splice(name.length - 1, 1)

            a.style.display = 'none';
            a.href = url;
            a.download = `${name.join('.')}.json`;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
        });
    }

    setAction(actionType, tid, filename){
        this.setState({
            selectedTemplateId: tid
        }, () => {
            if(actionType === 'delete') this.setInput('showDeleteConfirm', true);
            else if(actionType === 'json') this.downloadJSON(filename);
            else if(actionType === 'folder') this.setState({
                showFolderModal: true
            });
            else if(actionType === 'metadata') this.setState({
                generateMetaData: true
            });
            else if(actionType === 'publicurl'){
                axios.get(`${config.API_URL}api/v1/gettemplatemetadatabyid/${this.state.selectedTemplateId}/`)
                .then(() => {
                    window.open(`/public-form/${this.state.selectedTemplateId}/?token=${localStorage.getItem(config.TOKEN_KEY)}`, '_blank');
                })
                .catch(() => {
                    Alert.error('Please generate metadata in order to get the public url.', {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                })
            }
        });
    }

    getAllFolders(callback){
        this.setState({
            folderID: this.state.folderID,
            folders: [],
            templates: [],
            isLoading: true
        }, () => {
            axios.get(`${config.API_URL}api/v1/searchkeyword/`)
            .then(data => {
                callback(data.data.keywords);
            })
            .catch(() => {
                callback([]);
            })
        });
    }

    getTemplates(folders){
        if(this.state.hasMore){
            var sort = this.state.sortBy.value.split('_'),
                sortDirection = sort[sort.length - 1] === 'desc' ? -1 : 1,
                field = sort[0] === 'uploaddate' ? 'addedAt' : (sort[0] === 'filename' ? 'filename' : 'blocks'),
                resultCount = 15,
                skipCount = this.state.page * resultCount,
                link = `${config.API_URL}api/v1/document/?skipCount=${skipCount}&limit=${resultCount}&field=${field}&sort_direction=${sortDirection}&tags=${this.state.folderID ? encodeURIComponent(this.state.folderID) : 'none'}`;

            if(this.state.searchText){
                link += `&search_column=filename&search_value=${this.state.searchText}`
            }

            this.setState({
                isLoading: true
            }, () => {
                axios.get(link)
                .then(data => {
                    if(this.state.page === 0){
                        this.setState({
                            isLoading: false,
                            templates: [],
                            page: this.state.page + 1,
                            hasMore: data.data.documents.length < resultCount ? false : true
                        }, () => {
                            this.setState({
                                folders: folders ? folders : [],
                                templates: data.data.documents
                            });
                        })
                    }
                    else{
                        this.setState({
                            isLoading: false,
                            templates: [...this.state.templates, ...data.data.documents],
                            page: this.state.page + 1,
                            hasMore: data.data.documents.length < resultCount ? false : true
                        });
                    }
                })
            });
        }
    }

    deleteTemplate(){
        this.setState({
            showDeleteConfirm: false
        }, () => {
            if(this.state.selectedTemplateId){
                axios.delete(`${config.API_URL}api/v1/document/${this.state.selectedTemplateId}/`)
                .then(data => {
                    this.setState({
                        selectedTemplateId: undefined
                    }, () => {
                        Alert.success('One template has been deleted.', {
                            position: 'bottom-right',
                            effect: 'slide',
                            timeout: 5000
                        });
                        this.initialTemplateCall();
                    });
                })
            }
            else if(this.state.selectedTemplates.length > 0){
                var arr = [],
                    i = 0;
                for(; i<this.state.selectedTemplates.length; i++)
                    arr.push(axios.delete(`${config.API_URL}api/v1/document/${this.state.selectedTemplates[i]}/`));
                
                axios.all(arr)
                .then(() => {
                    this.setState({
                        selectedTemplates: []
                    }, () => {
                        Alert.success('All selected templates has been deleted.', {
                            position: 'bottom-right',
                            effect: 'slide',
                            timeout: 5000
                        });
                        this.initialTemplateCall();
                    });
                })
            }
        });
    }

    handleScroll(){
        if(this.state.anchor){
            let position = commonFunction.getPosition(this.state.anchor);
            if(position.y < (window.innerHeight + 150) && !this.state.isLoading && this.state.hasMore){
                this.getTemplates();
            }
        }
        else this.setState({
            anchor: document.getElementById('loading-anchor')
        });
    }

    initialTemplateCall(e, folders){
        if(e){ 
            e.preventDefault();
            this.setState({
                formSubmitted: true
            });
        }

        this.setState({
            page: 0,
            hasMore: true
        }, () => {
            this.getTemplates(folders ? folders : this.state.folders);
        })
    }

    clearFilter(){
        this.setState({
            searchText: "",
            sortBy: this.state.sortChoice[0],
            formSubmitted: false
        }, () => {
            this.initialTemplateCall();
        })
    }

    saveMetadata(metadata, groupdata, tid, create, output){
        if(create){
            axios.all([
                axios.post(`${config.API_URL}api/v1/createtemplatemetadata/`, {
                    templeteId: tid,
                    data: metadata
                }),
                axios.post(`${config.API_URL}api/v1/createtemplatemetagroup/`, {
                    templeteId: tid,
                    data: groupdata
                }),
                // axios.post(`${config.API_URL}api/v1/createtemplateoutput/`, {
                //     templeteId: tid,
                //     output
                // })
            ])
            .then(axios.spread(() => {
                this.setState({
                    selectedTemplateId: undefined,
                    generateMetaData: false
                }, () => {
                    Alert.success('Awesome, metadata has been creted successfully.', {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    })
                })
            }))
        }
        else{
            axios.all([
                axios.put(`${config.API_URL}api/v1/updatetemplatemetadata/${tid}/`, {
                    templeteId: tid,
                    data: metadata
                }),
                axios.put(`${config.API_URL}api/v1/updatetemplatemetagroup/${tid}/`, {
                    templeteId: tid,
                    data: groupdata
                }),
                // axios.put(`${config.API_URL}api/v1/createtemplateoutput/${tid}/`, {
                //     templeteId: tid,
                //     data: output
                // })
            ])
            .then(axios.spread(() => {
                this.setState({
                    selectedTemplateId: undefined,
                    generateMetaData: false
                }, () => {
                    Alert.success('Awesome, metadata has been updated successfully.', {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    })
                })
            }))
        }
    }

    mergeTemplate(){
        axios.post(`${config.API_URL}api/v1/document/merge/`, {
            documentIds: this.state.selectedTemplates,
            mergedFileName: `${this.state.mergeFileName}.${this.state.mergeFileFormat}`
        })
        .then(data => {
            this.initialTemplateCall();
            this.setState({
                selectedTemplates: [],
                showMergePopup: false,
                mergeFileName: "",
                mergeFileFormat: ""
            })
        });
    }

    componentDidMount(){
        this.getAllFolders((data) => {
            this.initialTemplateCall(undefined, data);
            window.addEventListener('scroll', this.handleScroll);
        });
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){
        return (
            <div>
                <Head customTitle={"Template"}/>
                <Header></Header>
                <SideNav index={2}></SideNav>
                <main className="dashboard-container template">
                    <div className="main-container">
                        <div className="searchbar">
                            <form method="post" onSubmit={(e) => this.initialTemplateCall(e)}>
                                <div className="flex-container">
                                    <div className="form-group flexbox two-third left">
                                        <div className="custom-search-bar relative">
                                            <span className="search-icon"></span>
                                            <input type="text" name="search-text" className="input-field" placeholder="Search templates by typing name" autoComplete="off" value={this.state.searchText} onChange={(e) => this.setInput('searchText', e.target.value)} />
                                            {this.state.formSubmitted && <span className="clear text-uppercase" onClick={() => this.clearFilter()}>Clear</span>}
                                        </div>
                                    </div>
                                    <div className="flexbox one-third right">
                                        <div className="sort relative">
                                            <div className="click-overlay" onClick={() => this.setInput('sortSelection', !this.state.sortSelection)}></div>
                                            <div className={this.state.sortSelection ? "sortby open" : "sortby"}>
                                                Sort by : 
                                                <span className="select-text">{this.state.sortBy.text}</span>
                                                <span className="down-arrow"></span>
                                                <ul className="sort-list">
                                                    {
                                                        this.state.sortChoice.map((el, index) => (
                                                            <li key={index} className={el.value === this.state.sortBy.value ? 'selected' : ''} onClick={() => this.setInput('sortBy', el)}>
                                                                <label className="sort-label">
                                                                    {el.text}
                                                                </label>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="body">
                            <div className="flex-container">
                                <div className="flexbox two-third left">
                                    <ListGridHeading
                                        initViewType={this.state.viewType}
                                        viewChange={(viewType) => {
                                            this.setState({viewType});
                                        }}
                                        selectAll={() => {
                                            this.setInput('selectAll')
                                        }}
                                        name={[
                                            this.state.viewType === 'list' && !this.state.folderID && <span key={'name'}>Name</span>,
                                            this.state.folderID && 
                                            <div key={'folderId'}>
                                                <span className="home" onClick={() => this.setState({folderID:undefined}, () => {
                                                    this.getAllFolders((data) => this.initialTemplateCall(undefined, data))
                                                })}>Home</span>
                                                <span className="separator">/</span>
                                                <span>{this.state.folderID}</span>
                                            </div>
                                        ]}
                                        allSelected={
                                            this.state.templates.length && 
                                            this.state.selectedTemplates.length === this.state.templates.length
                                        }
                                    />
                                    <div className={`${this.state.viewType}-container`}>
                                    {
                                        !this.state.folderID && this.state.folders.map((el, index) => {
                                            return el ? (
                                                <div className={`data-row ${this.state.viewType} folder`} key={index}>
                                                    {this.state.viewType === 'list' && <ListItem type='list' filename={el} ttype="folder" action={this.setInput}></ListItem>}
                                                    {this.state.viewType === 'grid' && <ListItem type='grid' filename={el} ttype="folder" action={this.setInput}></ListItem>}
                                                </div>
                                            ) : undefined;
                                        })
                                    }
                                    {
                                        this.state.templates.map((el, index) => (
                                            <div className={`data-row ${this.state.viewType}`} key={index}>
                                                {this.state.viewType === 'list' && <ListItem type='list' filename={el.originalFileName || el.filename} date={el.addedAt} size={el.size} elid={el._id} generate={this.setInput} action={this.setAction} role={this.state.role} section="template">
                                                    <div className="form-group">
                                                        <label className="custom-input" data-type="checkbox">
                                                            <input type="checkbox" name="select[]" value={this._id} checked={this.state.selectedTemplates.indexOf(el._id) > -1} onChange={() => this.setInput('select', el._id)}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </ListItem>}
                                                {this.state.viewType === 'grid' && <ListItem type='grid' filename={el.originalFileName || el.filename} date={el.addedAt} size={el.size} elid={el._id} generate={this.setInput} action={this.setAction} role={this.state.role} section="template">
                                                    <div className="form-group">
                                                        <label className="custom-input" data-type="checkbox">
                                                            <input type="checkbox" name="select[]" value={this._id} checked={this.state.selectedTemplates.indexOf(el._id) > -1} onChange={() => this.setInput('select', el._id)}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </ListItem>}
                                            </div>
                                        ))
                                    }
                                    </div>
                                    <div id="loading-anchor" className="load-anchor text-center">
                                        {this.state.isLoading && 
                                            <div className="loading vertical-middle">
                                                <span className="loading-text">Loading...</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flexbox one-third right">
                                    {this.state.role !== '3' && 
                                        <div>
                                            <button type="button" className="btn with-image moral full" onClick={() => this.setInput('uploadFile', true)}>
                                                <img src={add} className="btn-img" alt="icon"/>
                                                Upload a template
                                            </button>

                                            <div className={this.state.selectedTemplates.length > 1 ? "btn-link" : "btn-link disabled"} onClick={() => this.setState({showMergePopup:true})}>
                                                <img src={merge} className="merge icon" alt="icon"/>
                                                Merge templates
                                            </div>

                                            <div className={this.state.selectedTemplates.length > 0 ? "btn-link" : "btn-link disabled"} onClick={() => this.setInput('showDeleteConfirm', true)}>
                                                <img src={dlt} className="delete icon" alt="icon"/>
                                                Delete templates
                                            </div>
                                        </div>
                                    }
                                    {this.state.role === '3' && 
                                        <div className="btn-link" onClick={() => this.initialTemplateCall()}>
                                            <img src={refresh} className="delete icon" alt="icon"/>
                                            Refresh templates
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.uploadFile && 
                        <TemplateUploader callback={this.initialTemplateCall} close={this.setInput} folderID={this.state.folderID} folders={this.state.folders}></TemplateUploader>
                    }
                    {this.state.selectedTemplateId && this.state.generateDoc && 
                        <DocumentGenerator
                            tid={this.state.selectedTemplateId}
                            tname={this.state.selectedTemplateName}
                            callback={this.setInput}
                            submit={this.generateDocument}
                            draftf={this.generateDraft}
                        />
                    }
                    {this.state.selectedTemplateId && this.state.generateMetaData &&
                        <FieldMetaData
                            tid={this.state.selectedTemplateId}
                            callback={this.setInput}
                            submit={this.saveMetadata}
                        />
                    }
                    {this.state.showDeleteConfirm && 
                        <DeleteConfirmModal
                            disabeStatus={false}
                            desc={"This will delete the template you have uploaded"}
                            buttonText="Delete template"
                            close={() => {
                                this.setInput('showDeleteConfirm', false)
                            }}
                            doDelete={this.deleteTemplate}
                        />
                    }
                    {this.state.showMergePopup && 
                        <div className="full-page-overlay">
                            <div className="flex-container full-height vertical-middle">
                                <div className="template-uploader">
                                    <div className="modal-body relative">
                                        <div className="heading">Write down the name of the merged file</div>
                                        <div className="close" onClick={() => this.setState({showMergePopup: false})}>
                                            <img src={dltBtn} alt="close"/>
                                        </div>
                                        <p className="desc">Write down the name of the file, after the template merge this will the name of the generated file.</p>
                                        <div className="form-group has-field">
                                            <label htmlFor="merge-file-name">Merged file name<span>*</span></label>
                                            <input type="text" id="merge-file-name" className="input-field" placeholder="Wrte down the name of the merged file" autoComplete="off" onChange={(e) => this.setState({mergeFileName: e.target.value})} required="required" value={this.state.mergeFileName}/>
                                        </div>
                                        <div className="form-group has-field">
                                            <label htmlFor="file-format">File format <span>*</span></label>
                                            <Select id="file-format" className="input-select" value={this.state.formatOptions.filter(option => option.value === this.state.mergeFileFormat)} isSearchable={true} name="format" placeholder="Select format of the merged file" options={this.state.formatOptions} onChange={(e) => this.setState({mergeFileFormat: e.value})}/>
                                        </div>
                                        <div className="footer text-right">
                                            <button className="btn" onClick={() => this.setState({showMergePopup: false})}>Cancel</button>
                                            <button className="btn moral" disabled={!this.state.mergeFileName || !this.state.mergeFileFormat} onClick={() => this.mergeTemplate()}>Merge templates</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.showFolderModal && 
                        <Folder callback={this.setInput} folders={this.state.folders} tid={this.state.selectedTemplateId}/>
                    }
                </main>
            </div>
        )
    }
}

export default Template;
