import React, {Component} from 'react';
import axios from 'axios';
import { config } from '../../components/constant';
import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import ProgressRing from "../../components/progress-ring";
import {commonFunction} from "../../components/common-functions";

require("../../static/css/dashboard.css");

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            templateCount: 0,
            templateLastGenerated: commonFunction.formatDate(new Date()),
            generatedDocumentCount: 0,
            documentLastGenerated: commonFunction.formatDate(new Date()),
            imageCount: 0,
            imageLastUploaded: commonFunction.formatDate(new Date()),
            logs: []
        };
    }

    componentDidMount(){
        axios.all([
            axios.get(`${config.API_URL}api/v1/document/count`),
            axios.get(`${config.API_URL}api/v1/document/generate/count`),
            axios.get(`${config.API_URL}api/v1/image`),
            axios.get(`${config.API_URL}api/v1/events?startIndex=0&endIndex=10`),
            axios.get(`${config.API_URL}api/v1/output`),
            axios.get(`${config.API_URL}api/v1/document/?skipCount=0&limit=1&field=addedAt&sort_direction=-1`)
        ])
        .then(axios.spread((firstResponse, secondResponse, thirdResponse, logResp, fourthResponse, fifthResp) => {  
            this.setState({
                templateCount: firstResponse.data.count,
                templateLastGenerated: commonFunction.formatDate(new Date(fifthResp.data.documents[fifthResp.data.documents.length - 1].addedAt)),
                generatedDocumentCount: secondResponse.data.count,
                imageCount: thirdResponse.data.images.length,
                imageLastUploaded: thirdResponse.data.images.length > 0 ? commonFunction.formatDate(new Date(thirdResponse.data.images[thirdResponse.data.images.length - 1].addedAt)) : 'N/A',
                logs: logResp.data.events,
                documentLastGenerated: commonFunction.formatDate(new Date(fourthResponse.data.output[fourthResponse.data.output.length - 1].addedAt))
            })
        }))
        .catch(error => console.log(error));
    }

    render(){
        return (
            <div>
                <Head customTitle={"Dashboard"}/>
                <Header></Header>
                <SideNav index={1}></SideNav>
                <main className="dashboard-container dashboard">
                    <div className="main-container">
                        <div className="flex-container block-container">
                            <div className="flexbox template block one-third">
                                <div className="flex-container vertical-middle">
                                    <div className="flexbox graph">
                                        <ProgressRing stroke="5" radius="45" progress={(this.state.templateCount/50)*100}></ProgressRing>
                                    </div>
                                    <div className="flexbox data">
                                        <div className="main-number">{this.state.templateCount}</div>
                                        <div className="description text-uppercase">Template uploaded</div>
                                        <div className="last-update">Last uploaded: {this.state.templateLastGenerated}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flexbox document block one-third">
                                <div className="flex-container vertical-middle">
                                    <div className="flexbox graph">
                                        <ProgressRing stroke="5" radius="45" progress={(this.state.generatedDocumentCount/1000)*100}></ProgressRing>
                                    </div>
                                    <div className="flexbox data">
                                        <div className="main-number">{this.state.generatedDocumentCount}</div>
                                        <div className="description text-uppercase">Document generated</div>
                                        <div className="last-update">Last generated: {this.state.documentLastGenerated}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flexbox image block one-third">
                                <div className="flex-container vertical-middle">
                                    <div className="flexbox graph">
                                        <ProgressRing stroke="5" radius="45" progress={(this.state.imageCount/50)*100}></ProgressRing>
                                    </div>
                                    <div className="flexbox data">
                                        <div className="main-number">{this.state.imageCount}</div>
                                        <div className="description text-uppercase">Images uploaded</div>
                                        <div className="last-update">Last uploaded: {this.state.imageLastUploaded}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-container block-container">
                            <div className="flexbox block two-third">
                                <h2 className="heading">Template upload vs document generation</h2>
                            </div>
                            <div className="flexbox block one-third">
                                <h2 className="heading">LOGS: latest 10 logs</h2>
                                {
                                    this.state.logs.map((el, index) => (
                                        <div className="logs" key={index}>
                                            <div className="log-type">
                                                <span className="name">{el.type}</span>
                                                <span className="time">{commonFunction.getDateFormat(el.time)}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default Dashboard;
