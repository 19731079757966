import React, {useState} from "react";

const DotMenu = (props) => {
  const {submenus = []} = props;
  const [openSubmenu, setOpenSubmenu] = useState(false);

  return (
    <div className={`buttons relative auto-width${openSubmenu ? ' open' : ''}`}>
      <button className="btn bullet" onClick={() => setOpenSubmenu(!openSubmenu)}>
        <span>&bull;&bull;&bull;</span>
      </button>
      <div className="actions">
        <ul>
          {
            submenus.map((el, index) => {
              const {title, click} = el;
              return (
                <li
                  key={index}
                  onClick={() => {
                    setOpenSubmenu(false);
                    click();
                  }}
                >{title}</li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
};

export default DotMenu;
