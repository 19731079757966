import React from 'react';

const Nanobar = React.forwardRef((props, ref) => {
    const {color} = props; 
    const animationEnd = (e) => {
        var dstate = e.target.getAttribute('data-state');
        if(dstate === 'paused') e.target.classList.remove('running');
    };

    return (
        <div ref={ref} style={{
                backgroundColor: `var(--moral, ${color})`
            }} 
            className="nanobar" 
            onAnimationIteration={(e) => animationEnd(e)} 
            data-state="paused"
            id="nanobar"
        >
        </div>
    );
});

export default Nanobar;