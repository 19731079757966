import React, {Component} from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../constant';
import { commonFunction } from "../common-functions";

import dlt from "../../static/images/delete.png";
import dltBtn from "../../static/svg/delete.svg";
import plus from "../../static/images/plus.png";


const SingleValue = ({ ...props }) => {
    // console.log(props.data);
    return (
        <components.SingleValue {...props}>
            <div className="image-select-cont">
                {props.data.image ? <div style={{backgroundImage: `url(${props.data.image})`}} className="image-select"></div> : null}
                <span>{props.data.label}</span>
            </div>
        </components.SingleValue>
    )
};

const SingleOption = ({ ...props }) => {
    // console.log(props);
    return (
        <components.Option {...props}>
            <div className="image-select-cont">
                {props.data.image ? <div style={{backgroundImage: `url(${props.data.image})`}} className="image-select"></div> : null}
                <span>{props.label}</span>
            </div>
        </components.Option>
    );
};

class FormInput extends Component{
    constructor(props){
        super(props);
        this.state = {
            tid: this.props.tid,
            markers: [],
            data: {},
            tableInputs: {},
            disabledDraftButton: true,
            disabledNextButton: true,
            images: [],
            paras: [],
            draftNameModal: false,
            draftName: this.props.draft ? this.props.draft.name : "",
            draftFormat: this.props.draft ? this.props.draft.format : 'docx',
            netWorkCall: false,
            hasDraft: this.props.draft ? true : false,
            draftId: this.props.draft ? this.props.draft._id : undefined,
            folderName: this.props.folder || undefined
        }
    };

    setformInput(e, skey, type, tableKey){
        var dataObject = JSON.parse(JSON.stringify(this.state.data)),
            tableInputs = JSON.parse(JSON.stringify(this.state.tableInputs));
        
        if(type === 'select') dataObject[skey] = e.value;
        else if(type === 'input') dataObject[skey] = e.target.value;
        else if(type === 'table') tableInputs[skey]['fields'][tableKey] = e.target.value;
        else if(type === 'table-select') tableInputs[skey]['fields'][tableKey] = e.value;
        else if(type === 'delete'){
            var index = tableInputs[skey].selectedIndex.indexOf(tableKey);
            if(index === -1) tableInputs[skey].selectedIndex.push(tableKey);
            else tableInputs[skey].selectedIndex.splice(index, 1);
        }
        
        this.setState({
            data: dataObject,
            tableInputs: tableInputs
        }, () => {
            this.checkFormValidity(type === 'table' || type === 'table-select' ? skey : undefined);
        });
    }

    handleCreate(value, key, type, tableKey){
        var options = this.state.images,
            split = value.split('/'),
            newOption = {value: value, label: split[split.length - 1], image: value};
      
        this.setState({
            images: [...options, newOption]
        }, () => {
            this.setformInput({value:value}, key, type, tableKey);
        });
    }

    bindEnter(e, key){
        if(e.keyCode === 13)
            if(this.state.tableInputs[key].isValid)
                this.addTableRow(key);
    }

    addTableRow(key){
        if(this.state.tableInputs[key].isValid){
            var data = JSON.parse(JSON.stringify(this.state.data)),
                tableInputs = JSON.parse(JSON.stringify(this.state.tableInputs)),
                skey;

            data[key].push({ ...tableInputs[key].fields });
            tableInputs[key].isValid = false;
            for(skey in tableInputs[key].fields)
                if(tableInputs[key].fields.hasOwnProperty(skey)) tableInputs[key].fields[skey] = "";

            this.setState({
                data: data,
                tableInputs: tableInputs
            }, () => {
                this.checkFormValidity();
            });
        }
    }

    deleteTableRow(key){
        if(this.state.tableInputs[key].selectedIndex.length > 0){
            var data = JSON.parse(JSON.stringify(this.state.data)),
                tableInputs = JSON.parse(JSON.stringify(this.state.tableInputs)),
                i = 0,
                indexes = commonFunction.quickSort(tableInputs[key].selectedIndex);
            
            for(i = indexes.length - 1; i >= 0; i--)
                data[key].splice(indexes[i], 1);
            
            tableInputs[key].selectedIndex = [];
            this.setState({
                data: data,
                tableInputs: tableInputs
            }, () => {
                this.checkFormValidity();
            });
        }
    }

    checkFormValidity(ckey){
        var response;
        if(ckey){
            var tableInputs = JSON.parse(JSON.stringify(this.state.tableInputs));
            response = commonFunction.isObjectValid(tableInputs[ckey].fields);
            
            tableInputs[ckey].isValid = (response.key === response.flag);
            this.setState({
                tableInputs: tableInputs
            });
        }
        else{
            response = commonFunction.isObjectValid(this.state.data)
            this.setState({
                disabledDraftButton: !(response.flag > 0),
                disabledNextButton: !(response.key === response.flag)
            })
        }
        this.props.callback(this.props.skey, this.state.data);
    }

    getImages(){
        axios.get(`${config.API_URL}api/v1/image/`)
        .then(data => {
            var tempArray = [],
                i = 0,
                token = localStorage.getItem(config.TOKEN_KEY);

            for(; i<data.data.images.length; i++)
                tempArray.push({value: data.data.images[i]._id, label: data.data.images[i].filename, image: `${config.API_URL}api/v1/image/download/${data.data.images[i]._id}?access_token=${token}`});
            
            // console.log(tempArray);
            this.setState({
                images: tempArray
            })
        })
    }

    getParas(){
        axios.get(`${config.API_URL}api/v1/paragraph/`)
        .then(data => {
            var tempArray = [],
                i = 0;

            for(; i<data.data.paragraphs.length; i++)
                tempArray.push({value: data.data.paragraphs[i].text, label: data.data.paragraphs[i].name});
            
            // console.log(tempArray);
            this.setState({
                paras: tempArray
            })
        })
    }

    saveDraft(e){
        if(e) e.preventDefault();
        this.setState({
            netWorkCall: true
        }, () => {
            var allCalls = [];
            const reqObject = {
                markers: this.state.data,
                documentId: this.state.tid,
                name: this.state.draftName,
                format: this.state.draftFormat,
                async: true
            };
            if(this.state.folderName) reqObject.tags = [this.state.folderName];

            allCalls.push(axios.post(`${config.API_URL}api/v1/draft/`, reqObject));

            if(this.state.hasDraft) allCalls.push(axios.delete(`${config.API_URL}api/v1/draft/${this.state.draftId}/`))
            
            axios.all(allCalls)
            .then(axios.spread((data, delt) => {
                if(data.data.msg){
                    if(delt && delt.status === 200) console.log(1);
                    this.setState({
                        draftName: this.state.hasDraft ? this.props.draft.name : "",
                        draftNameModal: false,
                        netWorkCall: false
                    }, () => {
                        Alert.success(`The draft has been successfully ${this.state.hasDraft ? 'updated' : 'created'}.`, {
                            position: 'bottom-right',
                            effect: 'slide',
                            timeout: 5000
                        });
                        // Close the form window
                        if(this.props.close) this.props.close('selectedTemplateId', undefined);
                    });
                }
            }));
        });
    }

    componentDidMount(){
        var markers = JSON.parse(JSON.stringify(this.props.markers)),
            tempArray = [],
            dataObject = {},
            tableObject = {},
            isImage = false,
            isParagraph = false,
            imageApiCalled = false,
            paraApiCalled = false;

        Object.keys(markers).forEach(key => {
            if(markers[key] === true){ 
                isImage = commonFunction.isImageField(key);
                isParagraph = commonFunction.isParaField(key);
                
                if(isImage && !imageApiCalled){
                    imageApiCalled = true;
                    this.getImages();
                }
                if(isParagraph && !paraApiCalled){
                    paraApiCalled = true;
                    this.getParas();
                }

                tempArray.push({
                    key: key,
                    istable: false,
                    isImage: isImage,
                    isPara: isParagraph,
                    fields: []
                });
                dataObject[key] = this.props.data[key] || "";
            }
            else{ 
                var fields = [],
                    hasImage = false;

                tableObject[key] = {
                    isValid: false,
                    selectedIndex: [],
                    fields: {}
                };

                Object.keys(markers[key]).forEach(fkey => {
                    isImage = commonFunction.isImageField(fkey);
                    isParagraph = commonFunction.isParaField(fkey);
                    
                    if((isImage || isParagraph) && !hasImage) hasImage = true; 
                    if(isImage && !imageApiCalled){
                        imageApiCalled = true;
                        this.getImages();
                    }
                    if(isParagraph && !paraApiCalled){
                        paraApiCalled = true;
                        this.getParas();
                    }

                    fields.push({
                        key: fkey,
                        isImage: isImage,
                        isPara: isParagraph
                    });
                    tableObject[key]['fields'][fkey] = "";
                });

                tempArray.push({
                    key: key,
                    istable: true,
                    fields: fields,
                    hasImage: hasImage
                });
                dataObject[key] = this.props.data[key] || [];
            }
        });

        this.setState({
            markers: tempArray,
            data: dataObject,
            tableInputs: tableObject
        }, () => {
            this.checkFormValidity();
        });

        // console.log(this.props.draft);
    }

    render(){
        return(<div>
            <div className="form-container">
                {
                    this.state.markers.map((el, index) => (
                        <div key={index + el.key} className="form-group has-field">
                            {!el.istable && 
                            <div>
                                <label htmlFor={el.key}>{el.isImage || el.isPara ? el.key.split(' ')[0] : el.key.replace(/_/g, ' ')} <span>*</span></label>
                                
                                {!el.isImage && !el.isPara && <input type="text" id={el.key} className="input-field" placeholder={`Write down the data for "${el.key.replace(/_/g, ' ')}"`} autoComplete="off" tabIndex={index} onChange={(e) => this.setformInput(e, el.key, 'input')} required="required" value={this.state.data[el.key]}/>}

                                {el.isImage && <CreatableSelect id={el.key} value={this.state.images.filter(option => option.value === this.state.data[el.key])} className="input-select" name={`input-image-${el.key}`} options={this.state.images} placeholder={`Select image for ${el.key.split(' ')[0]}`} onChange={(e) => this.setformInput(e, el.key, 'select')} onCreateOption={(e) => this.handleCreate(e, el.key, 'select')} components={{ Option: SingleOption, SingleValue: SingleValue }}/>}

                                {el.isPara && <Select id={el.key} value={this.state.paras.filter(option => option.value === this.state.data[el.key])} className="input-select" name={`input-para-${el.key}`} options={this.state.paras} placeholder={`Select paragraph for ${el.key.split(' ')[0]}`} onChange={(e) => this.setformInput(e, el.key, 'select')}/>}
                            </div>
                            }
                            {el.istable && 
                            <div>
                                <label htmlFor={el.key}>{el.key} <span>*</span></label>
                                <table className={`table${el.hasImage ? ' has-image' : ''}`}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <img src={dlt} className={`icon ${this.state.tableInputs[el.key].selectedIndex.length === 0 ? 'disabled' : ''}`} alt="icon" onClick={() => this.deleteTableRow(el.key)}/>
                                            </th>
                                            {
                                                el.fields.map((fel, findex) => (
                                                    <th key={findex}>{fel.isImage || fel.isPara ? fel.key.split(' ')[0] :  fel.key}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.data[el.key] && Array.isArray(this.state.data[el.key]) && 
                                            this.state.data[el.key].map((del, dindex) => (
                                                <tr key={dindex}>
                                                    <td>
                                                        <label className="custom-input" data-type="checkbox">
                                                            <input type="checkbox" name="troe[]" onChange={(e) => this.setformInput(e, el.key, 'delete', dindex)} checked={this.state.tableInputs[el.key].selectedIndex.indexOf(dindex) > -1}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    {
                                                        el.fields.map((fel, findex) => (
                                                            <td key={findex}>{del[fel.key]}</td>
                                                        ))
                                                    }      
                                                </tr>
                                            ))
                                        }
                                        <tr className="input">
                                            <td>
                                                <img src={plus} className={`plus icon${this.state.tableInputs[el.key].isValid?'' : ' disabled'}`} alt="icon" onClick={() => this.addTableRow(el.key)}/>
                                            </td>
                                            {
                                            el.fields.map((fel, findex) => (
                                                <td key={findex}>
                                                    {!fel.isImage && !fel.isPara && <input type="text" className="input-field" placeholder={`Data for "${fel.key.replace(/_/g, ' ')}"`} autoComplete="off" tabIndex={index + findex + 1} required="required" onChange={(e) => this.setformInput(e, el.key, 'table', fel.key)} value={this.state.tableInputs[el.key].fields[fel.key]} onKeyUp={(e) => this.bindEnter(e, el.key)}/>}

                                                    {fel.isImage && <CreatableSelect id={fel.key} value={this.state.images.filter(option => option.value === this.state.tableInputs[el.key].fields[fel.key])} className="input-select" name={`input-image-${el.key}-${fel.key}`} options={this.state.images} components={{Option: SingleOption, SingleValue: SingleValue }} placeholder={`Select image for ${el.key.split(' ')[0]}`} onChange={(e) => this.setformInput(e, el.key, 'table-select', fel.key)}onCreateOption={(e) => this.handleCreate(e, el.key, 'table-select', fel.key)} />}

                                                    {fel.isPara && <Select id={fel.key} value={this.state.paras.filter(option => option.value === this.state.tableInputs[el.key].fields[fel.key])} className="input-select" name={`input-para-${el.key}-${fel.key}`} options={this.state.paras} placeholder={`Select paragraph for ${el.key.split(' ')[0]}`} onChange={(e) => this.setformInput(e, el.key, 'table-select', fel.key)}/>}
                                                </td>
                                            ))
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                    ))
                }
            </div>

            <div className="footer text-right">
                {!this.props.hidedraft && <button className="btn green" disabled={this.state.disabledDraftButton} onClick={() => (this.state.hasDraft ? this.saveDraft() : this.setState({draftNameModal: true}))}>{this.state.hasDraft ? 'Update draft' : 'Save draft'}</button>}
                <button className="btn moral" disabled={this.state.disabledNextButton} onClick={() => this.props.callback('stepNumber', 1)}>Next step</button>
            </div>

            {this.state.draftNameModal && 
                <div className="full-page-overlay">
                    <div className="flex-container full-height vertical-middle">
                        <div className="template-uploader">
                            <div className="modal-body relative">
                                <div className="heading">Write down the name of the draft</div>
                                <div className="close" onClick={() => this.setState({draftNameModal: false})}>
                                    <img src={dltBtn} alt="close"/>
                                </div>
                                <p className="desc">Write down the name of the draft to find it easily.</p>
                                <form method="post" onSubmit={(e) => this.saveDraft(e)}>
                                    <div className="form-group has-field">
                                        <label htmlFor="draft-name">Draft name<span>*</span></label>
                                        <input type="text" id="draft-name" className="input-field" placeholder="Write down the name of the draft" autoComplete="off" onChange={(e) => this.setState({draftName: e.target.value})} required="required" value={this.state.draftName}/>
                                    </div>
                                    <div className="footer text-right">
                                        <button className="btn" type="button" onClick={() => this.setState({draftNameModal: false})}>Cancel</button>
                                        <button className="btn moral" disabled={!this.state.draftName || this.state.netWorkCall} type="submit">Save draft</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>)
    }
}

export default FormInput;