import React, {Component} from 'react';
import Head from "../components/head";
import Logo from "../static/images/logo.png";
import { config } from '../components/constant';
import Alert from 'react-s-alert';
import axios from 'axios';

require('../static/css/login.css');


class AzureLogin extends Component{
    constructor(props){
        super(props);
        const query = new URLSearchParams(window.location.search);
        this.state = {
            token: query.get("token"),
            text: 'Verifying user login via Microsoft...',
            user: null
        };
    }

    componentDidMount(){
          axios({
            method: "post",
            url: `${config.API_URL}api/v1/user/token`,
            data: {},
            headers: {
            'x-access-token': this.state.token
            }
          }).then((res) => {
            this.setState({
                text: 'Microsoft login successful, you will be redirected to dashboard, shortly!'
            }, () => {
                setTimeout(function() {
                    //Set the token in local storage and launch templates.
                    localStorage.setItem(config.TOKEN_KEY, this.state.token);
                    localStorage.setItem(config.USER_ROLE_KEY, res.data.user.role);
                    this.setState({
                        user: res.data.user
                    });
                    window.user = res.data.user;
                    this.props.history.push("/template/");
                }.bind(this), 2000);
            })
        }).catch((err) => {
            console.log(err);
            Alert.error('Sorry, something went terribly wrong!', {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 6000
            })
        });



        console.log(this.state);
    }
    
    render(){
        return (
            <div>
                <Head customTitle={"User Logged in via Azure"}/>
                <section className="signup-holder">
                    <div className="login-header text-center">
                        <img src={Logo} className="company-icon" alt="logo"/> 
                        {/* <h3></h3> */}
                        <h3 className="text-uppercase">{this.state.text}</h3>
                    </div>
                </section>
            </div>
        )
    }
}

export default AzureLogin;