import React, {useState, useEffect, Fragment, useCallback} from "react";
import Select from "react-select";

import {config} from "../constant";
import {RequestHook} from "../hookRequest";
import {commonFunction} from "../common-functions";

const CRMIntegrationData = (props) => {
  const {callback, data, skey} = props;

  const {showAlert} = commonFunction;
  const {crm = "", hostUrl:phostURL = "", headers: pHeaders = []} = data;
  const {UI_DOMAIN, CLOUD_DATA_INTEGRATIONS} = config;
  
  const [hostURL, setHostURL] = useState(phostURL);
  const [selectedCRM, setSelectedCRM] = useState(crm);
  const [isFormValid, setIsFormValid] = useState(false);
  const [integratedCRMs, setIntegratedCRMs] = useState([]);
  const [disabledNextStep, setDisabledNextStep] = useState(pHeaders.length > 0);

  const setCallbackData = useCallback((headers) => {
    callback(skey, {
      crm: selectedCRM,
      hostUrl: hostURL,
      headers,
      file: []
    });
    setDisabledNextStep(true);
  }, [skey, callback, selectedCRM, hostURL]);

  const integration = `api/v1/integrations/`;
  const {loading:inteLoading, data:inteData} = RequestHook(integration);
  useEffect(() => {
    if(!inteLoading && inteData){
      const {data = []} = inteData;
      const crmKeys = CLOUD_DATA_INTEGRATIONS.reduce((data, el) => {
        const {name, path} = el;
        data[path] = name;
        return data;
      }, {});
      const integratedCRMs = data.filter(el => crmKeys[el.platform] !== undefined).map(el => ({
        label: crmKeys[el.platform],
        value: el.platform
      }));
      setIntegratedCRMs(integratedCRMs);
    }
  }, [CLOUD_DATA_INTEGRATIONS, inteLoading, inteData]);

  const {
    loading: fetchLoading, data: fetchData, callAPI: fetchRecordAPI, error, errorMessage
  } = RequestHook(`api/v1/${selectedCRM}/records`, false, {
    hostUrl: hostURL,
    reqOrigin: UI_DOMAIN
  }, "POST");
  useEffect(() => {
    if(!fetchLoading && fetchData){
      const {status, data} = fetchData;
      if(!status || !Array.isArray(data) || data.length === 0){
        showAlert(`Unable to fetch data for ${selectedCRM}, please check the host url properly!`, "error");
        return;
      }

      const [firstObject] = data;
      const headers = Object.keys(firstObject).filter(key => {
        return firstObject[key] !== undefined && firstObject[key] !== null && !key.includes(".")
      });
      setCallbackData(headers);
      showAlert("Data fetched successfully, please click on the Next Step to proceed!");
    }
  }, [selectedCRM, fetchData, fetchLoading, setCallbackData, showAlert]);

  useEffect(() => {
    if(error && errorMessage){
      showAlert(errorMessage, "error");
    }
  }, [error, errorMessage, showAlert]);

  useEffect(() => {
    if(selectedCRM && hostURL){
      setIsFormValid(true);
    }
  }, [selectedCRM, hostURL]);

  return (
    <div>
      {inteLoading && 
        <div className="load-anchor text-center">
          <span className="loading-text">Loading...</span>
        </div>
      }
      {
        !inteLoading && 
        <Fragment>
          {integratedCRMs.length === 0 &&
            <div className="load-anchor text-center">
              <p>No CRMs has been integrated. Please integrate one to proceed.</p>
              <a href="/integrations/" className="btn-link center">Integrate CRMs</a>
            </div>
          }
          {integratedCRMs.length > 0 &&
            <Fragment>
              <div className="form-container">
                <div className="form-group has-field">
                  <label htmlFor="CRM" >Select Integrated CRM <span>*</span></label>
                  <Select 
                    id="CRM" 
                    className="input-select" 
                    isSearchable={true} 
                    placeholder="Select the integrated CRM" 
                    name="CRM" 
                    value={integratedCRMs.filter(option => option.value === selectedCRM)}
                    options={integratedCRMs} 
                    onChange={(e) => setSelectedCRM(e.value)} 
                  />
                </div>

                <div className="form-group has-field">
                  <label htmlFor="hostURL">Host URL <span>*</span></label>
                  <input 
                    type="text" 
                    id="hostURL" 
                    className="input-field" 
                    placeholder="Enter the Host URL" 
                    onChange={(e) => setHostURL(e.target.value)} 
                    required="required" 
                    value={hostURL}
                  />
                </div>
              </div>

              <div className="footer text-right">
                <button 
                  className="btn green" 
                  id="databaseConnect" 
                  disabled={!isFormValid} 
                  onClick={() => fetchRecordAPI()}
                >Fetch Record</button>
                <button 
                  className="btn moral" 
                  id="nextDatabaseStep" 
                  disabled={!disabledNextStep} 
                  onClick={() => callback('stepNumber', 1)}
                >Next Step</button>
              </div>
            </Fragment>
          }
        </Fragment>
      }
    </div>
  )
};

export default CRMIntegrationData;
