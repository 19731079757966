import React, {useState, Fragment, useEffect} from "react";
import Select from "react-select";

import {RequestHook} from "../../hookRequest";

const StepOne = (props) => {
  const {setStep, stepData, callback, skey} = props;
  const {cronjobName: pcronjobName = "", template = "", markers: pmarkers = {}} = stepData;
  
  const [markers, setMarkers] = useState(pmarkers);
  const [allTemplates, setAllTemplates] = useState([]);
  const [cronjobName, setCronjobName] = useState(pcronjobName);
  const [selectedTemplate, setSelectedTemplate] = useState(template);
  const [disabledNextButton, setDisabledNextButton] = useState(true);

  const {data, loading} = RequestHook("api/v1/document/");
  useEffect(() => {
    if(!loading && data) {
      const {documents} = data;
      setAllTemplates(documents.map(({_id: value, filename: label}) => ({
        label, value
      })));
    }
  }, [data, loading]);

  const {
    data: markersData, loading: markersLoading, callAPI
  } = RequestHook(`api/v1/document/markers/${selectedTemplate}/`, false);
  useEffect(() => {
    if(!markersLoading && markersData){
      const {markers} = markersData;
      setMarkers(markers);
    }
  }, [markersLoading, markersData]);

  useEffect(() => {
    if(selectedTemplate){
      callAPI();
    }
  }, [selectedTemplate, callAPI]);

  useEffect(() => {
    if(cronjobName && selectedTemplate && !markersLoading && markers){
      callback(skey, {
        cronjobName,
        template: selectedTemplate,
        markers
      });
      setDisabledNextButton(false);
      return;
    }
    setDisabledNextButton(true);
  }, [cronjobName, selectedTemplate, markersLoading, markers, callback, skey]);

  return (
    <Fragment>
      <div className="form-container">
        <div className="form-group has-field">
          <label htmlFor="cronjobName">Automation Name <span>*</span></label>
          <input 
            type="text" 
            id="cronjobName" 
            className="input-field" 
            placeholder="Write down the name of the automation" 
            onChange={(e) => setCronjobName(e.target.value)} 
            value={cronjobName} 
          />
        </div>

        <div className="form-group has-field">
          <label htmlFor="template">Select template <span>*</span></label>
          <Select 
            id="template" 
            className="input-select" 
            value={allTemplates.filter(option => option.value === selectedTemplate)} 
            isSearchable={true} 
            name="template" 
            placeholder="Select uploaded template from the list" 
            options={allTemplates} 
            onChange={(e) => setSelectedTemplate(e.value)}
          />
        </div>
      </div>
      <div className="footer text-right">
        <button
          className="btn moral"
          disabled={disabledNextButton}
          onClick={() => setStep(1)}
        >Next step</button>
      </div>
    </Fragment>
  );
};

export default StepOne;
