import React, {Component} from 'react';
import axios from 'axios';

import {commonFunction} from "../../components/common-functions";
import { config } from '../../components/constant';
import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";

import dltBtn from "../../static/svg/delete.svg";
import refresh from "../../static/images/refresh.png";

class Logs extends Component{
    constructor(props){
        super(props);
        this.state = {
            logs: [],
            showDetails: false,
            details: ""
        };
    }

    getLogs(){
        axios.get(`${config.API_URL}api/v1/events?startIndex=0&endIndex=50`)
        .then(data => {
            this.setState({
                logs: data.data.events
            })
        })
    }

    showDetails(el){
        this.setState({
            showDetails: true,
            details: el.detailed_description
        })
    }

    componentDidMount(){
        this.getLogs();
    }

    render(){
        return (
            <div>
                <Head customTitle={"Logs"}/>
                <Header></Header>
                <SideNav index={7}></SideNav>
                <main className="dashboard-container template">
                    <div className="main-container">
                        <div className="body">
                            <div className="flex-container">
                                <div className="flexbox two-third left">
                                {
                                    this.state.logs.map((el, index) => (
                                        <div className="logs" key={index}>
                                            <div className="log-type">
                                                <span className="name">{el.type}</span>
                                                <span className="time">{commonFunction.getDateFormat(el.time)}</span>
                                            </div>
                                            <div className="log-desc">
                                                <span>{el.short_description}</span>
                                            </div>
                                            <div className="button">
                                                {el.detailed_description && <button className="btn moral" onClick={() => this.showDetails(el)}>See Details</button>}
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                                <div className="flexbox one-third right">
                                    <div className="btn-link" onClick={() => this.getLogs()}>
                                        <img src={refresh} className="delete icon" alt="icon"/>
                                        Refresh logs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showDetails && 
                        <div className="full-page-overlay">
                            <div className="flex-container full-height vertical-middle">
                                <div className="template-uploader">
                                    <div className="modal-body relative">
                                        <div className="heading">Details</div>
                                        <div className="close" onClick={() => this.setState({showDetails: false})}>
                                            <img src={dltBtn} alt="close"/>
                                        </div>
                                        <p className="desc">{this.state.details}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default Logs;