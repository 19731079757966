import React from 'react';
import Select from 'react-select';

const DocusignDynamicField = (props) => {
  const {
    rows, setRows, selectOptions, heading, buttonText, id,
    requiredFields
  } = props;

  return (
    <div className="form-group has-field group-creation">
      <div className="groups">
        {
          rows.map((row, index) => {
            return (
              <div className="group form-group" key={index}>
                <div className="group-name">
                  <div className="flex-container vertical-middle">
                    <div className="flexbox name">{heading}</div>
                    <div className="flexbox buttons">
                      {rows.length > 1 && 
                        <button
                          className="btn small"
                          onClick={() => {
                            const copyRows = [...rows];
                            copyRows.splice(index, 1);
                            setRows(copyRows);
                          }}
                        >Delete</button>
                      }
                    </div>
                  </div>
                </div>
                <div className="options">
                  <div className="flex-container vertical-middle radio-selection">
                    <div className="flexbox half">
                      <label htmlFor={`name-column-${id}-${index}`}>
                        Select name column
                        {requiredFields && <span>*</span>}
                      </label>
                      <Select
                        id={`name-column-${id}-${index}`}
                        className="input-select"
                        value={selectOptions.filter(option => option.value === row.name)}
                        isSearchable={true}
                        name="prefix"
                        options={selectOptions}
                        onChange={(e) => {
                          const copyRows = [...rows];
                          copyRows[index].name = e.value;
                          setRows(copyRows);
                        }}
                        placeholder="Select name column from uploaded data"
                      />
                    </div>
                    <div className="flexbox half">
                      <label htmlFor={`email-column-${id}-${index}`}>
                        Select email column
                        {requiredFields && <span>*</span>}
                      </label>
                      <Select
                        id={`email-column-${id}-${index}`}
                        className="input-select"
                        value={selectOptions.filter(option => option.value === row.email)}
                        isSearchable={true}
                        name="prefix"
                        options={selectOptions}
                        onChange={(e) => {
                          const copyRows = [...rows];
                          copyRows[index].email = e.value;
                          setRows(copyRows);
                        }}
                        placeholder="Select email column from uploaded data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        
        <button
          className="btn moral"
          onClick={() => {
            const copyRows = [...rows];
            copyRows.push({name: "", email: ""});
            setRows(copyRows);
          }}
        >{buttonText}</button>
      </div>
    </div>
  )
};

DocusignDynamicField.defaultProps = {
  requiredFields: true
}

export default DocusignDynamicField;