import React, {Component} from 'react';
import Head from "../components/head";
import Logo from "../static/images/logo.png";
import axios from 'axios';
import Alert from 'react-s-alert';
import { config } from '../components/constant';

require('../static/css/login.css');

class ResetPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            token: this.props.match.params.token,
            password: '',
            confirm_password: '',
            disabled: true
        };
    }

    setInput(e, type){
        var tempObject = {};
        tempObject[type] = e.target.value;
        this.setState(tempObject, () => {
            tempObject = {disabled: true};
            if(this.state.password && this.state.confirm_password && (this.state.password === this.state.confirm_password)) tempObject.disabled = false;
            this.setState(tempObject)
        });
    }

    checkPassword(type){
        if(((type === 'confirm_password' && this.state.password) || (type === 'password' && this.state.confirm_password)) && (this.state.password !== this.state.confirm_password)) Alert.error('Sorry! both passwords are not same.', {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 5000
        });
    }

    resetPassword(e){
        e.preventDefault();
        if(this.state.password && (this.state.password === this.state.confirm_password)){
            let formData = new FormData();
            formData.set('newpassword', this.state.password);
            formData.set('verifypassword', this.state.password);
            this.setState({
                disabled: true
            }, () => {
                axios({
                    method: 'post',
                    url: `${config.API_URL}password/${this.state.token}/`,
                    data: formData
                })
                .then(res => {
                    Alert.success(res.data.message, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                    this.props.history.push('/login/')
                })
                .catch(err => {
                    if(err.response && err.response.data) Alert.error(err.response.data.message, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                    else Alert.error(err, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                })
            });
        }
    }

    render(){
        return (
            <div>
                <Head customTitle={"Reset Password"}/>
                <section className="signup-holder">
                    <div className="login-header text-center">
                        <img src={Logo} className="company-icon" alt="logo"/> 
                        <h3 className="text-uppercase">Reset your password</h3>
                    </div>
                    <div className="login-form">
                        <form name="loginform" onSubmit={(e) => this.resetPassword(e)}>
                            <div className="form-group has-field password">
                                <label htmlFor="password">Enter the password<span>*</span></label>
                                <input id="password" type="password" className="input-field" title="Enter the password" autoComplete="off" tabIndex="3" required="required" value={this.state.password} onChange={(e) => this.setInput(e, 'password')} onBlur={() => this.checkPassword('password')}/>
                            </div>
                            <div className="form-group has-field password">
                                <label htmlFor="confirm_password">Confirm the password again<span>*</span></label>
                                <input id="confirm_password" type="password" className="input-field" title="Enter the password again" autoComplete="off" tabIndex="4" required="required" value={this.state.confirm_password} onChange={(e) => this.setInput(e, 'confirm_password')} onBlur={() => this.checkPassword('confirm_password')}/>
                            </div>
                            <button type="submit" className="btn moral full" disabled={this.state.disabled}>Reset password</button>
                        </form>
                    </div>
                </section>
            </div>
        )
    }
}

export default ResetPassword;