import React, {Component} from 'react';
import Head from "../components/head";
import Logo from "../static/images/logo.png";
import axios from 'axios';
import Alert from 'react-s-alert';
import { config } from '../components/constant';

require('../static/css/login.css');

class VerifyUser extends Component{
    constructor(props){
        super(props);
        this.state = {
            token: this.props.match.params.token,
            text: 'Verifying your email address...'
        };
    }

    componentDidMount(){
        axios.get(`${config.API_URL}verify/${this.state.token}/`)
        .then(() => {
            this.setState({
                text: 'Your account has been successfully verified, you will be redirected to login page, shortly!'
            }, () => {
                setTimeout(function() {
                    this.props.history.push("/login/");
                }.bind(this), 2000);
            })
        })
        .catch(() => {
            Alert.error('Sorry, something went terribly wrong!', {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 6000
            })
        })
    }
    
    render(){
        return (
            <div>
                <Head customTitle={"Verify email address"}/>
                <section className="signup-holder">
                    <div className="login-header text-center">
                        <img src={Logo} className="company-icon" alt="logo"/> 
                        {/* <h3></h3> */}
                        <h3 className="text-uppercase">{this.state.text}</h3>
                    </div>
                </section>
            </div>
        )
    }
}

export default VerifyUser;