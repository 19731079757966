import React from 'react';
import axios from 'axios';
import { Route } from 'react-router-dom';

const activateInterCeptor = (nanobar) => {
    if(!window.setOpenFlag){
        window.setOpenFlag = true;
        axios.interceptors.request.use((config) => {
            if(nanobar.current && !nanobar.current.classList.contains('running')){ 
                nanobar.current.classList.add('running');
                nanobar.current.setAttribute('data-state', 'running');
            }
            return config;
        });
        
        axios.interceptors.response.use((response) => {
            if(
                nanobar.current && 
                nanobar.current.classList.contains('running') && 
                nanobar.current.getAttribute('data-state') === 'running'
            ) nanobar.current.setAttribute('data-state', 'paused');
            return response;
        }, (error) => {
            if(
                nanobar.current && 
                nanobar.current.classList.contains('running') && 
                nanobar.current.getAttribute('data-state') === 'running'
            ) nanobar.current.setAttribute('data-state', 'paused');
            return Promise.reject(error);
        });
    }
}

const OpenRoute = ({nanobar, component: Component, ...rest }) => {
    activateInterCeptor(nanobar);
    return (
        <Route {...rest} render={(props) => <Component {...props} />}/>
    )
};

export default OpenRoute;