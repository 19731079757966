import React, {useState, useEffect, Fragment} from 'react';

import FileUpload from "./file-upload";
import { config } from '../constant';
import {useContextData} from "../../store/ContextData";
import CloudStorage from "./integrations/cloud-storage";
import { commonFunction } from "../common-functions";
import { RequestHook } from "../hookRequest";

import dltBtn from "../../static/svg/delete.svg";

const TemplateUploader = (props) => {
  const { userRole, nanobar } = useContextData();
  const {folders, folderID, close, callback} = props;
  const [documentId, setDocumentId] = useState("");
  const [isFlatten, setIsFlatten] = useState(true);
  const [sharedTo, setSharedTo] = useState(userRole === 1 ? ["2", "3"] : ["3"]);
  const [selectedFolder, setSelectedFolder] = useState(folderID ? [folderID] : []);
  const [fileInput, setFileInput] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);

  const moveToFolderLink = `api/v1/document/searchkeyword/`;
  const {
    loading:moveLoading, data:moveData, callAPI:moveToFolder
  } = RequestHook(moveToFolderLink, false, {
    documentId,
    tags: selectedFolder
  }, "POST");
  useEffect(() => {
    if(!moveLoading && moveData){
      callback();
      close('uploadFile', false);
    }
  }, [moveLoading, moveData, callback, close]);

  const shareDocLink = `api/v1/document/shared/`;
  const {
    loading:docLoading, data:docData, callAPI:shareTemplate
  } = RequestHook(shareDocLink, false, {
    documentId,
    roles: sharedTo
  }, "POST");
  useEffect(() => {
    if(!docLoading && docData){
      if(selectedFolder.length > 0){
        moveToFolder();
        return;
      }
      
      callback();
      close('uploadFile', false);
    }
  }, [docLoading, docData, selectedFolder, callback, close, moveToFolder]);

  const fileUploadCallback = (data) => {
    commonFunction.showAlert("Template has been uploaded successfully!");
    setDocumentId(data.id);
    setTimeout(() => {
      if(userRole !== 3 && sharedTo.length > 0){
        shareTemplate();
        return;
      }

      if(selectedFolder.length > 0){  
        moveToFolder();
        return;
      }
      
      callback();
      close('uploadFile', false);
    });
  };

  const sharedToHandler = (e) => {
    const userTypeID = e.target.value;
    const existing = new Set([...sharedTo]);
    if(existing.has(userTypeID)){
      existing.delete(userTypeID)
      setSharedTo([...existing]);
      return;
    }
    existing.add(userTypeID);
    setSharedTo([...existing]);
  };

  const cloudStorageDataCallback = (platform, data, otherData) => {
    nanobar.current.classList.add('running');
    nanobar.current.setAttribute('data-state', 'running');
    setShowLoading(true);
    const {name, file} = otherData;
    fetch(data)
    .then((resp) => resp.arrayBuffer())
    .then((blob) => {
      if(
        nanobar.current.classList.contains('running') && 
        nanobar.current.getAttribute('data-state') === 'running'
      ){ 
        nanobar.current.setAttribute('data-state', 'paused');
      }
      
      const {mimeType: type} = file;
      const createdFile = new File([blob], name, {type});
      setFileInput(createdFile);
      setShowLoading(false);
    })
    .catch(() => {
      commonFunction.showAlert("Unable to download the file!", 'error');
    });
  };

  return (
    <div className="full-page-overlay">
      <div className="flex-container full-height vertical-middle">
        <div className="template-uploader relative">
          <div className="modal-body relative">
            <div className="heading">Upload a template</div>
            <div className="close" onClick={() => close('uploadFile', false)}>
              <img src={dltBtn} alt="close"/>
            </div>
              
            <p className="desc">
              Drag and drop or click to select your <a 
                href="https://docescape.freshdesk.com/helpdesk/attachments/22012450623" 
                target="_blank" 
                rel="noopener noreferrer"
              >DOCX</a>, <a 
                href="https://www.edocgen.com/examples" 
                target="_blank" 
                rel="noopener noreferrer"
              >PDF</a>,
              <span className="green"> PPTX</span> or 
              <span className="green"> XLSX</span> template or upload from Integrated platforms
            </p>

            {userRole !== 3 && 
              <Fragment>
                <div className="form-group has-field">
                    <label htmlFor="role">Share the template with <span>*</span></label>
                    <div className="flex-container">
                      {userRole === 1 && 
                        <div className="flexbox">
                          <label className="custom-input" data-type="checkbox">
                            Manager
                            <input
                              type="checkbox"
                              name="shared[]"
                              value="2"
                              checked={
                                sharedTo.indexOf("2") > -1
                              }
                              onChange={(e) => sharedToHandler(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      }
                      <div className="flexbox">
                        <label className="custom-input" data-type="checkbox">
                          User
                          <input
                            type="checkbox"
                            name="shared[]"
                            value="3"
                            checked={
                              sharedTo.indexOf("3") > -1
                            }
                            onChange={(e) => sharedToHandler(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                </div>
                <div className="form-group has-field">
                  <label htmlFor="isFlatten">Flatten the template? <span>*</span></label>
                  <div className="flex-container">
                    <div className="flexbox">
                      <label className="custom-input" data-type="radio">
                        True
                        <input
                          type="radio"
                          name="isFlatten"
                          value="true"
                          checked={isFlatten}
                          onChange={() => setIsFlatten(true)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="flexbox">
                      <label className="custom-input" data-type="radio">
                        False
                        <input
                          type="radio"
                          name="isFlatten"
                          value="false"
                          checked={!isFlatten}
                          onChange={() => setIsFlatten(false)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group has-field">
                  <label htmlFor="role">Move the uploaded template to a folder</label>
                  <div className="flex-container wrap">
                    {
                      folders.length === 0 &&
                      <p>Create a folder by moving template to a folder</p>
                    }
                    {
                      folders.map((el, index) => (
                        <div className="flexbox half" key={index}>
                          <label className="custom-input" data-type="checkbox">
                            {el}
                            <input
                              type="checkbox"
                              name="folder[]"
                              value={el} checked={
                                selectedFolder.indexOf(el) > -1
                              }
                              onChange={() => setSelectedFolder((data) => {
                                const existing = new Set([...data]);
                                if(existing.has(el)){
                                  existing.delete(el)
                                  return [...existing];
                                }
                                existing.add(el);
                                return [...existing];
                              })}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </Fragment>
            }
            
            <FileUpload
              finput={{
                isFlatten
              }}
              file={fileInput}
              link={`${config.API_URL}api/v1/document/`}
              skey="documentFile"
              callback={fileUploadCallback}
              acf="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.slideshow,
              application/vnd.openxmlformats-officedocument.presentationml.presentation, 
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
              application/vnd.ms-excel"
            />

            <div className="or-separator"/>

            <CloudStorage
              callback={cloudStorageDataCallback}
              data={{
                platform: "",
                data: ""
              }}
              fileSelect={true}
            />
          </div>
          {showLoading && 
            <div className="loading-screen">
              Downloading file from cloud...
            </div>
          }
        </div>
      </div>
    </div>
  )
};

export default TemplateUploader;
