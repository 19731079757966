import React from 'react';
import axios from 'axios';
import { Route, Redirect } from 'react-router-dom';
import { config } from './constant';
import ContextData from "../store/ContextData";

const authenticateToken = function(nanobar){
    const token = localStorage.getItem(config.TOKEN_KEY),
        status = (token) ? true : false;
        
    if(status && !window.setFlag){
        window.setFlag = true;
        axios.interceptors.request.use((config) => {
            config.headers['x-access-token'] = `${token}`;
            if(nanobar.current && !nanobar.current.classList.contains('running')){ 
                nanobar.current.classList.add('running');
                nanobar.current.setAttribute('data-state', 'running');
            }
            return config;
        });

        axios.interceptors.response.use((response) => {
            if(
                nanobar.current && 
                nanobar.current.classList.contains('running') && 
                nanobar.current.getAttribute('data-state') === 'running'
            ) nanobar.current.setAttribute('data-state', 'paused');
            return response;
        }, (error) => {
            if(
                nanobar.current && 
                nanobar.current.classList.contains('running') && 
                nanobar.current.getAttribute('data-state') === 'running'
            ) nanobar.current.setAttribute('data-state', 'paused');
            if(error.response && error.response.status === 400 && error.response.data.message === 'Token Expired.'){
                localStorage.removeItem(config.TOKEN_KEY);
                localStorage.removeItem(config.USER_ROLE_KEY);
                window.location.pathname = '/login/';
            }
            return Promise.reject(error);
        });
    }
    return status;
};

const PrivateRoute = ({nanobar, component: Component, ...rest }) => {
    return (
        <ContextData nanobar={nanobar}>
            <Route {...rest} render={
                (props) => (
                    authenticateToken(nanobar) ? <Component {...props} /> : <Redirect to='/login/' />
                )
            }/>
        </ContextData>
    )
};

export default PrivateRoute