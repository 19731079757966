import React, {Component} from 'react';
import Helmet from 'react-helmet';
// Importing favicon icons
import favIco from "../static/images/favicon.ico";
import favIco180 from "../static/images/apple-touch-icon.png";
import favIco32 from "../static/images/favicon-32x32.png";
import favIco16 from "../static/images/favicon-16x16.png";

let author = "eDocGen",
    description = "DIY Document Generation Software for business users to create documents utilizing existing templates. Auto-generate business documents from your organization's databases, applications, or from simple fillable forms",
    title = "eDocGen - Dynamic Documents Generation Software",
    link = "https://www.edocgen.com",
    image = "https://www.edocgen.com/assets/images/logo.jpg",
    fbAppId = "2497533187137474",
    themeColor = '#33A913';

class Head extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: this.props.customTitle
        };
    }

    componentDidMount(){
        if(process.env.NODE_ENV !== 'development'){
            var script = document.createElement('script'),
                sScript = document.createElement('script'),
                body = (document.body || document.getElementsByTagName('body')[0]);

            script.type = sScript.type = "text/javascript";
            script.text = 'var continuallySettings = { appID: "ev3yvx64ypnw" };';

            sScript.src = 'https://cdn-app.continual.ly/js/embed/continually-embed.latest.min.js';
            sScript.async = true;

            body.appendChild(script);
            body.appendChild(sScript);
        }
    }

    render(){
        return (<Helmet
            title = {this.state.title ? `${author} - ${this.state.title}` : title}
            meta = {[
                {'http-equiv': "X-UA-Compatible", content: "IE=9"},
                {'http-equiv': "content-type", content: "text/html; charset=UTF-8"},
                {charset: "UTF-8"},
                {name: "viewport", content: "width=device-width, initial-scale=1, user-scalable=no"},
                {name: "author", content: author},
                {name: "description", content: description},
    
                // Open graph html tag set
                {property: "og:locale", content: "en_US"},
                {property: "og:type", content: "website"},
                {property: "og:site_name", content: author},
                {property: "og:url", content: link},
                {property: "og:title", content: (this.state.title ? `${author} - ${this.state.title}` : title)},
                {property: "og:description", content: description},
                {property: "og:image", content: image},
                {property: "og:image:alt", content: image},
                {property:"fb:app_id", content: fbAppId},
    
                // Twitter cards
                {property: "twitter:card", content: "summary_large_image"},
                {property: "twitter:site", content: link},
                {property: "twitter:creator", content: "@pulpkey"},
                {property: "twitter:title", content: (this.state.title ? `${author} - ${this.state.title}` : title)},
                {property: "twitter:description", content: description},
                {property: "twitter:image", content: image},
    
                // Theme color
                {property: "theme-color", content: themeColor},
                {property: "msapplication-navbutton-color", content: themeColor},
                {property: "apple-mobile-web-app-status-bar-style", content: themeColor}
            ]}
            link={[
                {rel: 'icon', type:"image/x-icon", href: favIco},
                {rel: 'apple-touch-icon', sizes:"180x180", href: favIco180},
                {rel: 'icon', type:"image/png", sizes:"32x32", href: favIco32},
                {rel: 'icon', type:"image/png", sizes:"16x16", href: favIco16}
            ]}
        />)
    }
}

export default Head;