import React, {useState, useRef, useEffect, useCallback} from 'react';
import { config } from '../constant';
import {useContextData} from "../../store/ContextData";
import upload from "../../static/svg/upload.svg";
import ProgressRing from "../progress-ring";
import { commonFunction } from "../common-functions";

const FileUpload = (props) => {
  const {link, skey, acf, file, finput, callback, isMultiple, selectableFileCount} = props;

  const dropArea = useRef();
  const {nanobar} = useContextData();
  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [text, setText] = useState('Drag and drop or click to upload');
  const [propFile, setPropFile] = useState(true);

  const acceptedFormats = (acf ||
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  );

  const handleSelectedFile = useCallback((files) => {
    if(files.length > selectableFileCount){
      setText(`You can choose maximum ${selectableFileCount} files at once!`);
      setFileSelected(false);
      return;
    }

    const allSimilarFile = Array.from(files).reduce((data, el) => {
      data.add(el.type);
      return data;
    }, new Set());
    if(allSimilarFile.size > 1){
      setText("The format of all selected files should be same!");
      setFileSelected(false);
      return;
    }

    setText(isMultiple && files.length > 1 ? `${files.length} files selected` : files.name);
    setFileSelected(true);

    const apiLink = typeof link === 'string' ? link : link[files[0].type];
    if(!apiLink){
      setProgress(100);
      setText('Upload complete!');
      setTimeout(() => {
        callback(undefined, isMultiple ? files : files[0]);
      }, 300);
      return;
    }

    if(nanobar.current && !nanobar.current.classList.contains('running')){ 
      nanobar.current.classList.add('running');
      nanobar.current.setAttribute('data-state', 'running');
    }

    const token = localStorage.getItem(config.TOKEN_KEY);
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    xhr.open('POST', apiLink, true);
    xhr.setRequestHeader('x-access-token', token);
    xhr.upload.addEventListener("progress", (e) => {
      const progress = (e.loaded / e.total)*100;
      setProgress(progress);
      if(progress >= 100){
        setText('Processing...');
      }
    });

    xhr.addEventListener('readystatechange', () => {
      if(xhr.readyState !== 4){
        return;
      }

      if(xhr.status === 200) {
        if(
          nanobar.current.classList.contains('running') && 
          nanobar.current.getAttribute('data-state') === 'running'
        ){ 
          nanobar.current.setAttribute('data-state', 'paused');
        }

        setText('Upload complete!');
        setTimeout(() => {
          callback(xhr.response, isMultiple ? files : files[0]);
        }, 300);
        return;
      }
      
      if(xhr.status !== 200) {
        if(
          nanobar.current.classList.contains('running') && 
          nanobar.current.getAttribute('data-state') === 'running'
        ){ 
          nanobar.current.setAttribute('data-state', 'paused');
        }
        const {message} = xhr.response;
        commonFunction.showAlert(
          (message || "Sorry, we are not able to process the uploaded template!"), 'error'
        );
        setText('Error! While uploading.');
      }
    });

    xhr.responseType = 'json';
    for (const file of files) {
      formData.append(skey, file);
    }
    if(finput){
      Object.keys(finput).forEach(key => {
        formData.append(key, finput[key]);
      });
    }
    xhr.send(formData);
  }, [callback, finput, link, nanobar, skey, isMultiple, selectableFileCount]);

  const handleManualSelect = (e) => {
    if(e && e.target && e.target.files && e.target.files.length > 0){
      handleSelectedFile(e.target.files);
      return;
    }

    setText('Drag and drop or click to upload');
    setFileSelected(false);
  };

  const handleFile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(e.type === 'dragenter' || e.type === 'dragover'){
      dropArea.current.classList.add('highlight');
      return;
    }
    
    if(e.type === 'dragleave' || e.type === 'drop'){
      dropArea.current.classList.remove('highlight');
    }

    if(e.type === 'drop'){
      const dt = e.dataTransfer;
      const {files} = dt;
      handleSelectedFile(files);
    }
  };

  useEffect(() => {
    if(file && propFile){
      setPropFile(false);
      setTimeout(() => {
        handleSelectedFile({0: file, length: 1});
      });
    }
  }, [file, propFile, handleSelectedFile]);

  return (
    <div
      id="drop-area"
      ref={dropArea}
      onDragEnter={(e) => handleFile(e)}
      onDragOver={(e) => handleFile(e)}
      onDragLeave={(e) => handleFile(e)}
      onDrop={(e) => handleFile(e)}
    >
      <form className="file-upload" encType="multipart/form-data">
        <label className="upload-area">
          <div className="flex-container full-height vertical-middle">
            <div className="flexbox text-center">
                {!fileSelected && 
                  <img src={upload} alt="upload icon"/>
                }
                {fileSelected && 
                  <ProgressRing
                    stroke="5"
                    radius="45"
                    progress={progress}
                  />
                }
                <span className="text">{text}</span>
                <input
                  type="file"
                  name="template"
                  className="input"
                  accept={acceptedFormats}
                  onChange={(e) => handleManualSelect(e)}
                  onClick={() => handleManualSelect(false)}
                  multiple={isMultiple}
                />
            </div>
          </div>
        </label>
      </form>
    </div>
  );
};

FileUpload.defaultProps = {
  isMultiple: false,
  selectableFileCount: 3
};

export default FileUpload;