import React, {Component, Fragment} from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';
import { config } from "../constant";
import Select from 'react-select'
import FileUpload from "./file-upload";
import CloudStorage from "./integrations/cloud-storage";
import { commonFunction } from "../common-functions";

class UploadDataFile extends Component{
    constructor(props){
        super(props);
        this.state = {
            tid: this.props.tid,
            disabledNextStep: true,
            file: undefined,
            format: "",
            sheetArray: [],
            sindex: [],
            headerin: [],
            headers: [],
            filterSelect: [],
            filterName: '',
            filterValue: '',
            fileInput: undefined,
            showLoading: false
        }
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.cloudStorageDataCallback = this.cloudStorageDataCallback.bind(this);
    }

    getHeader(callback){
        if(this.state.file){
            var formData = new FormData(),
                link;

            Array.from(this.state.file).forEach(file => {
                formData.append('inputFile', file);
            });
            formData.append("sheetindex", JSON.stringify(this.state.sindex));
            formData.append("headersin", JSON.stringify(this.state.headerin));
            formData.append("documentId", this.state.tid);
            formData.append("filterName", this.state.filterName);
            formData.append("filterValue", this.state.filterValue);

            if(this.state.format === 'json') link = `${config.API_URL}api/v1/document/getjsontags`;
            else if(this.state.format === 'xml') link = `${config.API_URL}api/v1/document/getxmltags`;
            else link = `${config.API_URL}api/v1/document/getxlsxheaders`;

            if(this.state.format === 'xlsx'){
                const isSheetSelected = this.state.sindex.every(el => el > -1);
                if(!isSheetSelected){
                    return;
                }
            }

            axios.post(link, formData)
            .then(data => {
                if(Array.isArray(data.data)){
                    this.setState({
                        headers: data.data
                    }, () => {
                        callback();
                    });
                }
                else Alert.error(data.data.message, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
            });
        }
        else callback();
    }

    setFilterSelect(data){
       var filterSelect = []
       var i;
       for (i = 0; i < data.length; i++) {
          filterSelect.push( { value: data[i], label: data[i] } );
       }

       this.setState({
          filterSelect: filterSelect,
       });
    }

    setformInput(e, key, index){
        const tempObject = {};
        const value = e && e.target ? e.target.value : e.value;

        if(index !== undefined){
            tempObject[key] = [...this.state[key]];
            tempObject[key][index] = value;
        }
        else{
            tempObject[key] = value;
        }

        this.setState(tempObject, () => {
            this.getHeader(() => {
                if(this.state.headers.length > 0) this.setState({
                    disabledNextStep: false
                });
                this.setFilterSelect( this.state.headers );
    
                this.props.callback(this.props.skey, {
                    sindex: this.state.sindex, 
                    headersin: this.state.headerin,
                    headers: this.state.headers,
                    format: this.state.format,
                    sheetArray: this.state.sheetArray,
                    file: this.state.file,
                    filterName: this.state.filterName,
                    filterValue: this.state.filterValue
                });
            });
        })
    }

    setformInputNoTarget(e, key) {
       this.setformInput( { 'target': { 'value': e.value } }, key)
    }


    fileUploadCallback(data, files){
        var fileType;
        if(files[0].type === 'application/json') fileType = 'json';
        else if(files[0].type === 'text/xml') fileType = 'xml';
        else fileType = 'xlsx';

        this.setState({
            format: fileType,
            disabledNextStep: true
        }, () => {
            if(this.state.format === 'xlsx'){
                if(data && Array.isArray(data)){
                    const headerin = new Array(data.length).fill("row");
                    const sindex = new Array(data.length).fill(-1);
                    const sheetArray = data.map(el => {
                        const sheets = el.sheets.map(el => ({value: el.index, label: el.name}))
                        return {
                            ...el,
                            sheets
                        }
                    });

                    this.setState({
                        headerin,
                        sindex,
                        sheetArray,
                        file: files
                    }, () => {
                        this.props.callback(this.props.skey, {
                            sindex: this.state.sindex, 
                            headersin: this.state.headerin,
                            headers: this.state.headers,
                            format: this.state.format,
                            sheetArray: this.state.sheetArray,
                            file: this.state.file,
                            filterName: this.state.filterName,
                            filterValue: this.state.filterValue
                        });
                    })
                }
            }
            else{
                this.setState({
                    file: files
                }, () => {
                    this.getHeader(() => {
                        if(this.state.headers.length > 0) this.setState({
                            disabledNextStep: false
                        });
                        this.setFilterSelect( this.state.headers );
            
                        this.props.callback(this.props.skey, {
                            sindex: this.state.sindex, 
                            headersin: this.state.headerin,
                            headers: this.state.headers,
                            format: this.state.format,
                            sheetArray: this.state.sheetArray,
                            file: this.state.file,
                            filterName: this.state.filterName,
                            filterValue: this.state.filterValue
                        });
                    });
                })
            }
        });        
    }

    componentDidMount(){
        const {data} = this.props;
        if(data){
            const {
                sindex, headersin: headerin = this.state.headerin, format, 
                headers = [], sheetArray = [], file, filterName, filterValue
            } = data;

            if( headers ) {
               this.setFilterSelect( headers );
            }
            this.setState({
                sindex: sindex !== undefined ? sindex : this.state.sindex,
                headerin,
                headers,
                format,
                sheetArray,
                file: file || this.state.file,
                filterName: filterName ? filterName : this.state.filterName,
                filterValue: filterValue ? filterValue : this.state.filterValue
            }, () => {
                if(this.state.headers.length > 0) this.setState({
                    disabledNextStep: false
                });
            })
        }
    }

    cloudStorageDataCallback(platform, data, otherData){
        this.setState({
            showLoading: true
        }, () => {
            const {name, file} = otherData;
            fetch(data)
            .then((resp) => resp.arrayBuffer())
            .then((blob) => {
                const {mimeType: type} = file;
                const createdFile = new File([blob], name, {type});
                this.setState({
                    fileInput: createdFile,
                    showLoading: false
                });
            })
            .catch(() => {
                commonFunction.showAlert("Unable to download the file!", 'error');
            });
        });
    }

    render(){
        return (
        <Fragment>
            <div className="form-container">
                <FileUpload 
                    file={this.state.fileInput}
                    link={{
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": `
                        ${config.API_URL}api/v1/document/getxlsxsheets
                        `, 
                        "application/vnd.ms-excel": `${config.API_URL}api/v1/document/getxlsxsheets`, 
                        "text/xml": undefined, 
                        "application/json": undefined
                    }}
                    skey="inputFile"
                    callback={this.fileUploadCallback}
                    acf={`text/xml, application/json, 
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel
                    `}
                    isMultiple={true}
                />

                <div className="or-separator"/>

                <CloudStorage
                    callback={this.cloudStorageDataCallback}
                    data={{
                        platform: "",
                        data: ""
                    }}
                    fileSelect={true}
                    acceptedMime={[
                        "text/xml", "application/json", 
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
                        "application/vnd.ms-excel"
                    ]}
                />

                {this.state.showLoading && 
                    <div className="loading-screen">
                    Downloading file from cloud...
                    </div>
                }

                {
                    (this.state.format === "xlsx" || this.state.headers.length > 0) && <div className="line-separator"/>
                }

                {
                    this.state.format === 'xlsx' && 
                    this.state.sheetArray.length > 0 && 
                    this.state.sheetArray.map((el, index) => (
                        <div className="form-group has-field" key={el.fileName + index}>
                            <div className="meta-container">
                                <h4 className="meta-heading">Select header and sheet for: {el.fileName}</h4>
                                <label htmlFor={`headerin-${index}`}>Headers in first <span>*</span></label>
                                <div className="flex-container vertical-middle radio-selection">
                                    <div className="flexbox half">
                                        <label className="custom-input" data-type="radio">
                                            Row
                                            <input
                                                type="radio"
                                                name={`headerin-${index}`}
                                                value="row"
                                                onChange={(e) => this.setformInput(e, 'headerin', index)}
                                                checked={this.state.headerin[index] === 'row'}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="flexbox half">
                                        <label className="custom-input" data-type="radio">
                                            Column
                                            <input
                                                type="radio"
                                                name={`headerin-${index}`}
                                                value="column"
                                                onChange={(e) => this.setformInput(e, 'headerin', index)}
                                                checked={this.state.headerin[index] === 'column'}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="help-text">
                                    <label htmlFor={`sheet-index-${index}`}>Select sheet <span>*</span></label>
                                    <Select
                                        id={`sheet-index-${index}`}
                                        className="input-select"
                                        value={
                                            el.sheets.filter(option => option.value === this.state.sindex[index])
                                        }
                                        isSearchable={true}
                                        placeholder="Select sheet of the uploaded excel file"
                                        name={`sheet-index-${index}`}
                                        options={el.sheets}
                                        onChange={(e) => this.setformInput(e, 'sindex', index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }

                {this.state.headers.length > 0 &&
                    <div className="form-group has-field">
                        <label htmlFor="filter">Filter</label>
                        <div className="flex-container vertical-middle" >
                            <div className="flexbox" style={{ 'width': '48%', 'margin': '0px' }} >
                                <Select
                                    id="filter"
                                    className="input-select"
                                    isSearchable={true}
                                    placeholder="Select the header"
                                    name="filterName"
                                    options={this.state.filterSelect}
                                    onChange={(e) => this.setformInputNoTarget(e, 'filterName')}
                                />
                            </div>
                            <div className="flexbox" style={{ 'width': '4%', 'textAlign': 'center' }}>~=</div>
                            <div className="flexbox" style={{ 'width': '48%', 'margin': '0px' }} >
                                <input
                                    style={{'borderTop': 0, 'borderLeft':0, 'borderRight':0}}
                                    type="text"
                                    className="input-field"
                                    placeholder="Matching value expression"
                                    autoComplete="off"
                                    value={this.state.filterValue}
                                    onChange={(e) => this.setformInput(e, 'filterValue')}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="footer text-right">
                <button
                    className="btn moral"
                    disabled={this.state.disabledNextStep}
                    onClick={() => this.props.callback('stepNumber', 1)}
                >Next Step</button>
            </div>
        </Fragment>
        );
    }
}

export default UploadDataFile;
