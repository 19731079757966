import React, {useState, Fragment, useEffect} from "react";
import Select from "react-select";

const Generation = (props) => {
  const {setStep, stepData, callback, skey, submit, isEdit} = props;
  const {name: pname = "", format: pformat = "pdf"} = stepData;

  const formatOptions = [
    {value: 'docx', label: 'DOCX'},
    {value: 'pdf', label: 'PDF'}
  ];
  const [name, setName] = useState(pname);
  const [format, setFormat] = useState(pformat);
  const [disableNextButton, setDisableNextButton] = useState(true);

  useEffect(() => {
    if(name && format){
      callback(skey, {
        name, format
      })
      setDisableNextButton(false);
      return;
    }
    setDisableNextButton(true);
  }, [name, format, skey, callback]);

  return (
    <Fragment>
      <div className="form-container">
        <div className="form-group has-field">
          <label htmlFor="file-format">File format <span>*</span></label>
          <Select
            id="file-format"
            className="input-select"
            value={
              formatOptions.filter(option => option.value === format)
            } 
            isSearchable={true} 
            name="format" 
            placeholder="Select format of the generated file" 
            options={formatOptions} 
            onChange={(e) => setFormat(e.value)}
          />
        </div>
        <div className="form-group has-field">
          <label htmlFor="file-name">File name <span>*</span></label>
          <input 
            type="text" 
            id="file-name" 
            className="input-field" 
            placeholder="Write down the name of the generated file" 
            autoComplete="off" 
            onChange={(e) => setName(e.target.value)} 
            required="required" 
            value={name}
          />
        </div>
      </div>
      <div className="footer text-right">
        <button 
          className="btn" 
          onClick={() => setStep(3)}
        >Back</button>
        <button 
          className="btn moral" 
          disabled={disableNextButton}
          onClick={submit}
        >{isEdit ? "Edit" : "Create"} automation</button>
    </div>
    </Fragment>
  )
};

export default Generation;
