export const steps = [{
  title: "Fillout the form fields",
  desc: "Fillout all the fields in the form to create automation",
  moreDesc: "Select a uploaded template and provide a name of the automation to proceed to next step"
}, {
  title: "Database connection details",
  desc: "Fillout all the database connection details to generate document",
  moreDesc: "Fill the database connection details and click on the connect button and then next button to go to next step"
}, {
  title: "Map fields",
  desc: "Map uploaded data fields with the fields of the template",
  moreDesc: `Map template fields with the fields present in the uploaded data, 
  by drag and drop the field to it's corresponding field.`
}, {
  title: "Schedule automation",
  desc: "Select the date and time to automate the document generation",
  moreDesc: `Set cronjob to generate document automatically and available 
  to output file on your email or on your ftp server`
}, {
  title: "Create automation",
  desc: "Complete automation by providing name of the file and the format",
  moreDesc: "Automate the document generation, get it by email or on your ftp server you have entered on previous step."
}];

export const printFormatValue = [{
  label: "PCL", 
  value: "pcl"
}, {
  label: "PostScript", 
  value: "post-script"
}];
