import React, {useState, useEffect, Fragment} from 'react';
import { commonFunction } from "../common-functions";
import Select from 'react-select';

const Generation = (props) => {
    const {
        callback, skey, markers, data, draft, 
        type, submit, originalFormat, isFlatten, tname
    } = props;
    const formatOptions = [
        {value: 'docx', label: 'DOCX'},
        {value: 'pdf', label: 'PDF'},
        {value: 'html', label: 'HTML'},
        {value: 'jpg', label: 'Image'},
        {value: 'xlsx', label: 'XLSX'},
        {value: 'pptx', label: 'PPTX'}
    ];
    const {
        format:dformat, name:dname, prefix:dprefix, 
        watermark:dwatermark, passwordColumn: dpasswordColumn
    } = data || {};
    
    const disableFormatSelection = originalFormat === "pdf" && !isFlatten;
    const fallbackFormat = tname ? tname.split(".").pop() : "";
    const [name, setName] = useState(dname || "");
    const [prefix, setPrefix] = useState(dprefix || "");
    const [prefixOptions, setPrefixOptions] = useState([]);
    const [watermark, setWatermark] = useState(dwatermark || false);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [passwordColumn, setPasswordColumn] = useState(dpasswordColumn || "");
    const [format, setFormat] = useState(disableFormatSelection ? "pdf" : (dformat || fallbackFormat || "docx"));

    useEffect(() => {
        const tmpPrefixOption = [...Object.keys(markers)].reduce((data, key) => {
            if(markers[key] !== true){
                const tableData = [...Object.keys(markers[key])].reduce((data, fkey) => {
                    data.push({value: key, label: `${key.replace(/_/g, ' ')} - ${fkey.replace(/_/g, ' ')}`});
                    return data;
                }, []);
                return [...data, ...tableData];
            }
            data.push({value: key, label: key.replace(/_/g, ' ')});
            return data;
        }, []);
        setPrefixOptions(tmpPrefixOption);
    }, [markers]);

    useEffect(() => {
        const {flag, key} = commonFunction.isObjectValid({format, name});
        setDisableNextButton(flag !== key);
        callback(skey, {
            format,
            name,
            prefix,
            watermark,
            passwordColumn
        });
    }, [format, name, skey, prefix, watermark, passwordColumn, callback])

    return (
        <Fragment>
            <div className="form-container">
                <div className="form-group has-field">
                    <label 
                        htmlFor="file-format"
                    >File format <span>*</span></label>
                    <Select
                        id="file-format"
                        className="input-select"
                        value={
                            formatOptions.filter(option => option.value === format)
                        } 
                        isSearchable={true} 
                        name="format" 
                        placeholder="Select format of the generated file" 
                        options={formatOptions} 
                        onChange={(e) => setFormat(e.value)}
                        isDisabled={disableFormatSelection}
                    />
                </div>
                <div className="form-group has-field">
                    <label 
                        htmlFor="file-name"
                    >File name <span>*</span></label>
                    <input 
                        type="text" 
                        id="file-name" 
                        className="input-field" 
                        placeholder="Write down the name of the generated file" 
                        autoComplete="off" 
                        onChange={(e) => setName(e.target.value)} 
                        required="required" 
                        value={name}
                    />
                </div>
                <div className="form-group has-field">
                    <label htmlFor="file-prefix">File name prefix</label>
                    <Select 
                        id="file-prefix" 
                        className="input-select" 
                        value={prefixOptions.filter(option => option.value === prefix)} 
                        isSearchable={true} 
                        name="prefix" 
                        placeholder="Select the prefix of the generated each file" 
                        options={prefixOptions} 
                        onChange={(e) => setPrefix(e.value)}
                    />
                </div>
                {format === 'pdf' && 
                    <Fragment>
                        <div className="form-group has-field">
                            <label htmlFor="password-column">Password Column</label>
                            <Select 
                                id="password-column" 
                                className="input-select" 
                                value={prefixOptions.filter(option => option.value === passwordColumn)} 
                                isSearchable={true} 
                                name="prefix" 
                                placeholder="Select the column for password field" 
                                options={prefixOptions} 
                                onChange={(e) => setPasswordColumn(e.value)}
                            />
                        </div>
                        <div className="form-group has-field">
                            <label htmlFor="header">Watermark needed?</label>
                            <div className="flex-container vertical-middle radio-selection">
                                <div className="flexbox half">
                                    <label className="custom-input" data-type="radio">
                                        Yes
                                        <input 
                                            type="radio"
                                            name="watermark"
                                            value="false"
                                            onChange={() => setWatermark(true)}
                                            checked={watermark === true}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="flexbox half">
                                    <label className="custom-input" data-type="radio">
                                        No
                                        <input 
                                            type="radio"
                                            name="watermark"
                                            value="false"
                                            onChange={() => setWatermark(false)}
                                            checked={watermark === false}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
            <div className="footer text-right">
                <button 
                    className="btn" 
                    onClick={() => callback('stepNumber', (
                        (type === 'upload' || type === 'database' || type === 'crm') ? 1 : 0)
                    )}
                >Back</button>
                {type === 'upload' && <button 
                    className="btn green" 
                    disabled={disableNextButton} 
                    onClick={() => submit(true) }
                >Preview</button>}
                {(type === 'upload' || type === 'database') && <button 
                    className="btn green" 
                    disabled={disableNextButton} 
                    onClick={() => {
                        setDisableNextButton(true);
                        draft();
                    }}
                >Save Draft</button>}
                {(type === 'upload' || type === 'database') && <button 
                    className="btn green" 
                    disabled={disableNextButton} 
                    onClick={() => {
                        setDisableNextButton(true);
                        submit();
                    }}
                >Generate</button>}
                <button 
                    className="btn moral" 
                    disabled={disableNextButton} 
                    onClick={() => {
                        if(type === 'input'){
                            setDisableNextButton(true);
                            submit();
                            return;
                        }
                        callback('stepNumber', 3)
                    }}
                >{(type === 'input') ? 'Finish' : 'Next step'}</button>
            </div>
        </Fragment>
    )
};

export default Generation;
