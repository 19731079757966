import React, {useState, Fragment} from 'react';
import {commonFunction} from "../common-functions";
import { config } from '../constant';
import {useContextData} from "../../store/ContextData";

import folder from "../../static/svg/folder.svg";

const ListItem = (props) => {
  const {nanobar} = useContextData();
  const {
    type, filename, date, size, ttype, section,
    children, action, generate, role = "1", elid 
  } = props;
  const {getDateFormat, getFileSize, getIcon} = commonFunction;
  const [showMenu, setShowMenu] = useState(false);

  const downloadTemplate = (elid, filename) => {
    setShowMenu(false);
    
    const token = localStorage.getItem(config.TOKEN_KEY);
    nanobar.current.classList.add('running');
    nanobar.current.setAttribute('data-state', 'running');

    fetch(`${config.API_URL}api/v1/document/download/${elid}?access_token=${token}`)
    .then(resp => resp.blob())
    .then(blob => {
      if(
        nanobar.current && nanobar.current.classList.contains('running') &&
        nanobar.current.getAttribute('data-state') === 'running'
      ){ 
        nanobar.current.setAttribute('data-state', 'paused');
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('oh no!'));
  };

  return (
    <Fragment>
      {type === 'list' && 
        <div className="flex-container vertical-middle">
            <div className="flexbox checkbox">
                {children}
            </div>
            <div className="flexbox icon-name">
                <div className="file-icon">
                    {ttype === 'folder' && <img src={folder} className="icon" alt="template type"/>}
                    {!ttype && <img 
                        src={getIcon(filename)}
                        className="icon"
                        alt="template type"
                    />}
                </div>
                <div className="details text-left">
                    <span className="name">{filename}</span>
                    {!ttype && <span className="date-size">
                        <span className="text-uppercase">{getDateFormat(date)}</span>
                        <span className="separator">-</span>
                        <span className="text-uppercase">{getFileSize(size)}</span>
                    </span>}
                </div>
            </div>
            <div className={`flexbox buttons relative${showMenu ? ' open' : ''}`}>
                {ttype === 'folder' && 
                  <button
                    className="btn bullet"
                    onClick={() => action('folderID', filename)}
                  >Open folder</button>
                }
                {section === 'template' && !ttype && 
                  <Fragment>
                    <button
                      className="btn"
                      onClick={() => {
                        generate('selectedTemplateId', {elid, name: filename})
                      }}
                    >Generate</button>
                    {role !== '3' && 
                      <button 
                        className="btn" 
                        onClick={() => action('delete', elid)}
                      >Delete</button>
                    }
                  </Fragment>
                }
                {section === 'output' && 
                  <Fragment>
                    <button
                      className="btn"
                      onClick={() => action('download', elid, filename)}
                    >Download</button>
                    {filename.indexOf('.zip') === -1 && 
                      <button
                        className="btn"
                        onClick={() => action('edit', elid)}
                      >Edit</button>
                    }
                  </Fragment>
                }
                {!ttype && 
                  <Fragment>
                    <button className="btn bullet" onClick={() => setShowMenu(!showMenu)}>
                      <span>&bull;&bull;&bull;</span>
                    </button>
                    <div className="actions">
                      {section === 'output' && 
                        <ul>
                          <li 
                            onClick={() => {
                              setShowMenu(false);
                              action('email', elid);
                            }}
                          >Email as attachment</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('print', elid, filename);
                            }}
                          >Print</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('upload', elid, filename);
                            }}
                          >Upload to cloud storage</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('delete', elid);
                            }}
                          >Delete...</li>
                        </ul>
                      }
                      {section === 'template' && 
                        <ul>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('json', elid, filename);
                            }}
                          >Generate JSON</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('metadata', elid);
                            }}
                          >Create/update metadata</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                            }}
                          >Generate response</li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              action('publicurl', elid);
                            }}
                          >Get public form url</li>
                          {role !== '3' && 
                            <li 
                              onClick={() => {
                                setShowMenu(false);
                                action('folder', elid, filename);
                              }}
                            >Move to folder</li>
                          }
                          <li 
                            onClick={() => downloadTemplate(elid, filename)}
                          >Download</li>
                        </ul>
                      }
                    </div>
                  </Fragment>
                }
            </div>
        </div>
      }
      {type === 'grid' && 
        <Fragment>
          <div className="header">
              <div className="icon-name">
                  <div className="file-icon">
                    {ttype === 'folder' && 
                      <img src={folder} className="icon" alt="template type"/>
                    }
                    {!ttype && 
                      <img src={getIcon(filename)} className="icon" alt="template type"/>
                    }
                  </div>
                  {!ttype && 
                    <div className={`buttons relative${showMenu ? ' open' : ''}`}>
                      <button className="btn bullet" onClick={() => setShowMenu(!showMenu)}>
                        <span>&bull;&bull;&bull;</span>
                      </button>
                      <div className="actions">
                        <ul>
                          {/* <li>Edit template</li> */}
                          {/* <li>Print</li> */}
                          {section === 'output' && 
                            <Fragment>
                              <li onClick={() => {
                                setShowMenu(false);
                                action('email', elid);
                              }}>Email as attachment</li>
                              <li onClick={() => {
                                setShowMenu(false);
                                action('print', elid, filename);
                              }}>Print</li>
                              <li
                                onClick={() => {
                                  setShowMenu(false);
                                  action('upload', elid, filename);
                                }}
                              >Upload to cloud storage</li>
                              <li onClick={() => {
                                setShowMenu(false);
                                action('delete', elid);
                              }}>Delete...</li>
                            </Fragment>
                          }
                          {section === 'template' &&
                            <Fragment>
                              <li
                                onClick={() => {
                                  setShowMenu(false);
                                  action('json', elid, filename);
                                }}
                              >Generate JSON</li>
                              <li 
                                onClick={() => {
                                  setShowMenu(false);
                                  action('metadata', elid);
                                }}
                              >Create/update metadata</li>
                              <li
                                onClick={() => {
                                  setShowMenu(false);
                                  action('publicurl', elid);
                                }}
                              >Get public form url</li>
                              {role !== '3' && 
                                <li
                                  onClick={() => {
                                    setShowMenu(false);
                                    action('folder', elid, filename);
                                  }
                                }>Move to folder</li>
                              }
                              <li onClick={() => {
                                downloadTemplate(elid, filename)
                              }}>Download</li>
                            </Fragment>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                  {children}
              </div>
          </div>
          <div className="icon-name">
            <div className="details text-left">
              <span className="name">{filename}</span>
              {!ttype && 
                <span className="date-size">
                  <span className="text-uppercase">{getDateFormat(date)}</span>
                  <span className="separator">-</span>
                  <span className="text-uppercase">{getFileSize(size)}</span>
                </span>
              }
            </div>
          </div>
          {!ttype && section === 'template' && 
            <div className="button-container">
              <button
                className="btn"
                onClick={() => generate('selectedTemplateId', elid)}
              >Generate</button>
              {role !== '3' &&
                <button
                  className="btn"
                  onClick={() => action('delete', elid)}
                >Delete</button>
              }
            </div>
          }
          {!ttype && section === 'output' && 
            <div className="button-container">
              <button
                className="btn"
                onClick={() => action('download', elid, filename)}
              >Download</button>
              {filename.indexOf('.zip') === -1 &&
                <button className="btn" onClick={() => action('edit', elid)}>Edit</button>
              }
            </div>
          }
          {ttype === 'folder' && 
            <div className="button-container">
              <button className="btn bullet" onClick={() => action('folderID', filename)}>Open folder</button>
            </div>
          }
        </Fragment>
      }
    </Fragment>
  );
};

export default ListItem;