import React, {Component} from 'react';
import Head from "../components/head";
import Logo from "../static/images/logo.png";
import axios from 'axios';
import Alert from 'react-s-alert';
import { Link } from "react-router-dom";
import { config } from '../components/constant';

require('../static/css/login.css');

class SignUp extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            confirm_password: '',
            referral: '',
            disabled: true
        };
    }

    setInput(e, type){
        var tempObject = {};
        tempObject[type] = e.target.value;
        this.setState(tempObject, () => {
            tempObject = {disabled: true};
            if(this.state.name && this.state.email && this.state.password && this.state.confirm_password && (this.state.password === this.state.confirm_password)) tempObject.disabled = false;
            this.setState(tempObject)
        });
    }

    checkPassword(type){
        if(((type === 'confirm_password' && this.state.password) || (type === 'password' && this.state.confirm_password)) && (this.state.password !== this.state.confirm_password)) Alert.error('Sorry! both passwords are not same.', {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 5000
        });
    }

    mailChilmSubs(callback){
        let formData = new FormData();
        formData.set('NAME', this.state.name);
        formData.set('EMAIL', this.state.email);

        axios.post('https://edocgen.us10.list-manage.com/subscribe/post?u=ea735e880238823983220bc6a&id=a4eb11c725', formData)
        .then(res => {
            callback(res);
        })
        .catch(err => {
            callback(err);
        })
    }

    signup(e){
        e.preventDefault();
        if(this.state.name && this.state.email && this.state.password && (this.state.password === this.state.confirm_password)){
            let formData = new FormData();
            formData.set('name', this.state.name);
            formData.set('email', this.state.email);
            formData.set('password', this.state.password);
            formData.set('referral', this.state.referral);

            axios({
                method: 'post',
                url: `${config.API_URL}register/`,
                data: formData
            })
            .then(res => {
                Alert.success(res.data.message, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                // Mailchimp subscription
                this.mailChilmSubs(() => {
                    this.props.history.push("/login/");
                });
            })
            .catch(err => {
                if(err.response && err.response.data) Alert.error(err.response.data.message, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                else Alert.error(err, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
            })
        }
    }

    render(){
        return (
            <div>
                <Head customTitle={"Sign Up"}/>
                <section className="signup-holder">
                    <div className="login-header text-center">
                        <img src={Logo} className="company-icon" alt="logo"/> 
                        <h3 className="text-uppercase">Start Automating Documents Creation</h3>
                        <p className="sub-text">Register with your Business Email.</p>
                    </div>
                    <div className="login-form">
                        <form name="loginform" onSubmit={(e) => this.signup(e)}>
                            <div className="form-group has-field email">
                                <label htmlFor="name">Enter your name<span>*</span></label>
                                <input type="text" id="name" autoFocus="autofocus" className="input-field" title="What's your name?" autoComplete="off" tabIndex="1" required="required" value={this.state.name} onChange={(e) => this.setInput(e, 'name')}/>
                            </div>
                            <div className="form-group has-field email">
                                <label htmlFor="email">Enter your work email<span>*</span></label>
                                <input type="email" id="email" autoFocus="autofocus" className="input-field" title="What's your work email address?" autoComplete="off" tabIndex="2" required="required" value={this.state.email} onChange={(e) => this.setInput(e, 'email')}/>
                            </div>
                            <div className="form-group has-field password">
                                <label htmlFor="password">Enter the password<span>*</span></label>
                                <input id="password" type="password" className="input-field" title="Enter the password" autoComplete="off" tabIndex="3" required="required" value={this.state.password} onChange={(e) => this.setInput(e, 'password')} onBlur={() => this.checkPassword('password')}/>
                            </div>
                            <div className="form-group has-field password">
                                <label htmlFor="confirm_password">Confirm the password again<span>*</span></label>
                                <input id="confirm_password" type="password" className="input-field" title="Enter the password again" autoComplete="off" tabIndex="4" required="required" value={this.state.confirm_password} onChange={(e) => this.setInput(e, 'confirm_password')} onBlur={() => this.checkPassword('confirm_password')}/>
                            </div>
                            <div className="form-group has-field password">
                                <label htmlFor="referral">Referral code (If any)</label>
                                <input id="referral" type="text" className="input-field" title="Enter the referral code if you have any" autoComplete="off" tabIndex="5" value={this.state.referral} onChange={(e) => this.setInput(e, 'referral')}/>
                            </div>
                            <button type="submit" className="btn moral full" disabled={this.state.disabled}>Sign up</button>
                            <div className="text-center link">
                                <Link to="/login/">Already have an account?</Link>
                            </div>
                            <div className="link">
                                <p>By creating new account, you are agreeing to eDocGen <a href="https://www.edocgen.com/edocgen-privacy-policy" rel="noreferrer nopener">privacy policy</a> and <a href="https://www.edocgen.com/edocgen-terms" rel="noreferrer nopener">terms</a></p>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )
    }
}

export default SignUp;