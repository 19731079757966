import React, {useEffect, Fragment} from 'react';
import axios from 'axios';

import { config } from '../components/constant';

require('../static/css/login.css');

const LogOut = () => {
    const hardLogout = () => {
        localStorage.removeItem(config.TOKEN_KEY);
        localStorage.removeItem(config.USER_ROLE_KEY);
        window.location.pathname = '/login/';
    };

    useEffect(() => {
        axios({
            method: 'post',
            url: `${config.API_URL}api/v1/logout/`
        })
        .then(() => {
            hardLogout();
        })
        .catch(error => {
            hardLogout();
        });
    }, []);

    return (
        <Fragment></Fragment>
    );
}

export default LogOut;
