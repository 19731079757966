import React, {useState, useEffect} from 'react';

import listActive from "../../static/svg/list.active.svg";
import listInActive from "../../static/svg/list.inactive.svg";
import gridActive from "../../static/svg/grid.active.svg";
import gridInActive from "../../static/svg/grid.inactive.svg";

const ListGridHeading = (props) => {
    const {
        name, initViewType, viewChange, selectAll, allSelected
    } = props;
    const [viewType, setViewType] = useState(initViewType);

    useEffect(() => {
        if(viewType !== initViewType){
            viewChange(viewType);
        }
    }, [initViewType, viewType, viewChange]);

    return (
        <div className="row-heading text-uppercase">
            <div className="flex-container vertical-middle">
                <div className="flexbox select-all">
                    <div className="form-group">
                        <label className="custom-input" data-type="checkbox">
                            <input 
                                type="checkbox" 
                                name="select-all" 
                                value="true" 
                                checked={allSelected}
                                onChange={() => {
                                    selectAll();
                                }}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="flexbox name">
                    {name}
                </div>
                <div className="flexbox view-type">
                    <div className="flex-container view vertical-middle">
                        <div 
                            className={`flexbox list ${viewType === 'list' ? 'active' : ''}`}
                            onClick={() => setViewType('list')}
                        >
                            <img src={viewType === 'list' ? listActive : listInActive} alt="icon"/>
                            <span>List</span>
                        </div>
                        <div 
                            className={`flexbox grid ${viewType === 'grid' ? 'active' : ''}`}
                            onClick={() => setViewType('grid')}
                        >
                            <img src={viewType === 'grid' ? gridActive : gridInActive} alt="icon"/>
                            <span>Grid</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListGridHeading.defaultProps = {
    initViewType: 'grid',
    name: 'Name',
    allSelected: false
};

export default ListGridHeading;