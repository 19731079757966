import React, {Component} from 'react';
import Alert from 'react-s-alert';
import axios from 'axios';

import { config } from '../constant';

import dltBtn from "../../static/svg/delete.svg";

class Folder extends Component{
    constructor(props){
        super(props);
        this.state = {
            tid: this.props.tid,
            folders: this.props.folders,
            selectedFolder: [],
            folderName: '',
            newFolder: false
        }; 
    }

    setInput(key, value){
        var tempObj = {},
            flag = true;
        switch(key){
            case 'cancel':
                flag = false;
                this.props.callback('showFolderModal', value);
            break;

            case 'selectedFolder':
                var selected = [...this.state.selectedFolder],
                    index = selected.indexOf(value);
                if(index === -1) selected.push(value);
                else selected.splice(index, 1);

                tempObj[key] = selected;
            break;

            default:
                tempObj[key] = value;
            break;
        }
        if(flag) this.setState(tempObj);
    }
    
    createFolder(){
        if(this.state.folderName){
            var allFolders = [...this.state.folders], 
                selectedFolder = [...this.state.selectedFolder],
                fname = this.state.folderName.toLowerCase();
            
            if(allFolders.indexOf(fname) === -1){
                allFolders.push(fname);
                selectedFolder.push(fname);
                this.setState({
                    folders: allFolders,
                    folderName: '',
                    newFolder: false,
                    selectedFolder: selectedFolder
                });
            }
            else Alert.error('Sorry! there is alreay a folder with same name.', {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 5000
            });
        }
    }

    moveToFolder(){
        if(this.state.selectedFolder.length > 0){
            var formData = new FormData();
            formData.set('documentId', this.state.tid);
            formData.set('tags', JSON.stringify(this.state.selectedFolder));

            axios.post(`${config.API_URL}api/v1/document/searchkeyword/`, formData)
            .then(() => {
                Alert.success(`Nice! The template has been moved to the selected folder.`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                this.props.callback('folderUpdated', true);
            })
        }
    }

    componentDidMount(){
        axios.get(`${config.API_URL}api/v1/document/searchkeyword/${this.state.tid}/`)
        .then((data) => {
            this.setState({
                selectedFolder: data.data.keywords ? data.data.keywords : []
            })
        })
    }

    render(){
        return (
            <div className="full-page-overlay">
                <div className="flex-container full-height vertical-middle">
                    <div className="template-uploader">
                        <div className="modal-body relative">
                            <div className="heading">Move templates to folders</div>
                            <div className="close" onClick={() => this.setInput('cancel', false)}>
                                <img src={dltBtn} alt="close"/>
                            </div>
                            <p className="desc">Select a folder or multiple folders to move the selected template into them</p>
                            <div className="form-group has-field">
                                {this.state.newFolder && <div className="form-group has-field">
                                        <label htmlFor="role">Write down the name of the folder <span>*</span></label>
                                        <input type="text" name="folder" className="input-field" value={this.state.folderName} onChange={(e) => this.setInput('folderName', e.target.value)}/>
                                    </div>
                                }
                                {this.state.newFolder && 
                                    <div className="footer text-right">
                                        <button className="btn" onClick={() => this.setInput('newFolder', false)}>Cancel</button>
                                        <button className="btn moral" onClick={() => this.createFolder()}>Create folder</button>
                                    </div>
                                }
                                {!this.state.newFolder && <button className="btn moral full" onClick={() => this.setInput('newFolder', true)}>Create a folder to select</button>}
                            </div>
                            {this.state.folders.length > 0 && !this.state.newFolder && 
                                <div>
                                    <div className="or-separator"></div>
                                    <div className="form-group has-field">
                                        <label htmlFor="role">Select existing one or many folders <span>*</span></label>
                                        <div className="flex-container wrap">
                                            {
                                                this.state.folders.map((el, index) => {
                                                    if(!el){ 
                                                        return undefined;
                                                    }
                                                    
                                                    return (
                                                        <div className="flexbox half" key={index}>
                                                            <label className="custom-input" data-type="checkbox">
                                                                {el}
                                                                <input type="checkbox" name="folder[]" value={el} checked={this.state.selectedFolder.indexOf(el) > -1} onChange={() => this.setInput('selectedFolder', el)}/>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="footer text-right">
                                        <button className="btn" onClick={() => this.setInput('cancel', false)}>Cancel</button>
                                        <button className="btn moral" disabled={this.state.selectedFolder.length === 0} onClick={(e) => this.moveToFolder()}>Move to folder</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Folder;
