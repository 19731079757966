import React, {Component} from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';
import { config } from '../../components/constant';
import { commonFunction } from "../../components/common-functions";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import ListItem from "../../components/dashboard/list-item";
import SideNav from "../../components/dashboard/side-navbar";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import DocumentGenerator from "../../components/dashboard/document-generator";

// Importing all images
import listActive from "../../static/svg/list.active.svg";
import listInActive from "../../static/svg/list.inactive.svg";
import gridActive from "../../static/svg/grid.active.svg";
import gridInActive from "../../static/svg/grid.inactive.svg";

class Drafts extends Component{
    constructor(props){
        super(props);
        this.state = {
            viewType: 'grid',
            documents: [],
            folders: [],
            drafts: [],
            showDeleteConfirm: false,
            selectedDraftIndex: undefined,
            selectedTemplateId: undefined,
            markerData: {},
            folderID: undefined,
            hasMore: true,
            page: 0,
            isLoading: false,
            anchor: undefined
        };
        this.setInput = this.setInput.bind(this);
        this.generateDocument = this.generateDocument.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
    }

    getMarkersCount(markers){
        var count = 0;
        Object.keys(markers).forEach(key => {
            count++;
        });
        return count;
    }


    deleteDraft(){
        axios.delete(`${config.API_URL}api/v1/draft/${this.state.drafts[this.state.selectedDraftIndex]._id}/`)
        .then(() => {
            var drafts = [...this.state.drafts];
            drafts.splice(this.state.selectedDraftIndex, 1);

            this.setState({
                selectedDraftIndex: undefined,
                showDeleteConfirm: false,
                drafts: drafts
            })
        });
    }

    setInput(key, data){
        var tempObject = {};
        tempObject[key] = data;
        this.setState(tempObject, () => {
            this.setState({
                drafts: [],
                page: 0,
                hasMore: true,
                isLoading: true
            }, () => {
                this.getDrafts();
            })
        });
    }

    openDocumentGeneration(el){
        var index = this.state.drafts.indexOf(el);
        this.setState({
            selectedTemplateId: el.documentId,
            markerData: el.markers,
            selectedDraftIndex: index
        })
    }

    getDrafts(){
        if(this.state.hasMore){
            this.setState({
                isLoading: true
            }, () => {
                var limit = 20;
                axios.get(`${config.API_URL}api/v1/draft/?skipCount=${this.state.page * limit}&limit=${limit}&tags=${this.state.folderID ? encodeURIComponent(this.state.folderID) : 'none'}`)
                .then((draft) => {
                    var i = 0,
                        tempArray = [],
                        tmpObj = {};

                    for(; i<draft.data.drafts.length; i++){
                        tmpObj = draft.data.drafts[i];
                        tmpObj.count = this.getMarkersCount(tmpObj.markers);
                        tmpObj.showMenu = false;
                        tempArray.push(tmpObj);
                    }

                    this.setState({
                        drafts: [...this.state.drafts, ...tempArray],
                        page: this.state.page + 1,
                        hasMore: draft.data.drafts.length < limit ? false : true,
                        isLoading: false
                    })
                })
            })
        }
    }

    handleScroll(){
        if(this.state.anchor){
            let position = commonFunction.getPosition(this.state.anchor);
            if(position.y < (window.innerHeight + 150) && !this.state.isLoading && this.state.hasMore){
                this.getDrafts();
            }
        }
        else this.setState({
            anchor: document.getElementById('loading-anchor')
        });
    }

    initialDraftCall(){
        this.setState({
            isLoading: true
        }, () => {
            axios.all([
                axios.get(`${config.API_URL}api/v1/draft/searchkeyword/`),
                axios.get(`${config.API_URL}api/v1/document/`)
            ])
            .then(axios.spread((folders, document) => {
                this.setState({
                    folders: folders.data.keywords.filter(el => el),
                    documents: document.data.documents
                }, () => {
                    this.getDrafts();
                    window.addEventListener('scroll', this.handleScroll);
                });
            }));
        })
    }

    generateDocument(type, submitData, filename){
        if(type === 'input'){
            axios.all([
                axios.post(`${config.API_URL}api/v1/document/generate/`, submitData),
                axios.delete(`${config.API_URL}api/v1/draft/${this.state.drafts[this.state.selectedDraftIndex]._id}/`)
            ])
            .then(axios.spread((data, delt) => {
                this.getDrafts();
                this.setState({
                    selectedTemplateId: undefined,
                    markerData: {},
                    selectedDraftIndex: undefined
                }, () => {
                    Alert.success(`Nice! ${filename} will be generated soon and will be available in output section.`, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                });
            }));
        }
    }

    toggleMenu(index, callback){
        var drafts = [...this.state.drafts];
        drafts[index].showMenu = !drafts[index].showMenu;
        this.setState({
            drafts: drafts
        }, () => {
            if(callback) callback();
        })
    }

    generateUrl(el){
        axios.get(`${config.API_URL}api/v1/gettemplatemetadatabyid/${el.documentId}/`)
        .then(() => {
            window.open(`/public-form/${el.documentId}/${el._id}/?token=${localStorage.getItem(config.TOKEN_KEY)}`, '_blank');
        })
        .catch(() => {
            Alert.error('Please generate metadata for the template in template section to get the public url.', {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 5000
            });
        })
    }

    componentDidMount(){
        this.initialDraftCall();
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    render(){
        return (
            <div>
                <Head customTitle={"Drafts"}/>
                <Header></Header>
                <SideNav index={5}></SideNav>
                <main className="dashboard-container drafts">
                    <div className="main-container">
                        <div className="body">
                            <div className="row-heading text-uppercase">
                                <div className="flex-container vertical-middle">
                                    {this.state.viewType === 'list' && 
                                        <div className="flexbox select-all">
                                            <span>Icon</span>
                                        </div>
                                    }
                                    <div className="flexbox name">
                                        {this.state.viewType === 'list' && !this.state.folderID && <span>Name</span>}
                                        {this.state.folderID && 
                                            <div>
                                                <span className="home" onClick={() => this.setInput('folderID', undefined)}>Home</span>
                                                <span className="separator">/</span>
                                                <span>{this.state.folderID}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="flexbox view-type">
                                        <div className="flex-container view vertical-middle">
                                            <div className={`flexbox list ${this.state.viewType === 'list' ? 'active' : ''}`} onClick={() => this.setState({viewType: 'list'})}>
                                                <img src={this.state.viewType === 'list' ? listActive : listInActive} alt="icon"/>
                                                <span>List</span>
                                            </div>
                                            <div className={`flexbox grid ${this.state.viewType === 'grid' ? 'active' : ''}`} onClick={() => this.setState({viewType: 'grid'})}>
                                                <img src={this.state.viewType === 'grid' ? gridActive : gridInActive} alt="icon"/>
                                                <span>Grid</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${this.state.viewType}-container`}>
                                {
                                    !this.state.folderID && this.state.folders.map((el, index) => (
                                        <div className={`data-row ${this.state.viewType} folder`} key={index}>
                                            {this.state.viewType === 'list' && <ListItem type='list' filename={el} ttype="folder" action={this.setInput}></ListItem>}
                                            {this.state.viewType === 'grid' && <ListItem type='grid' filename={el} ttype="folder" action={this.setInput}></ListItem>}
                                        </div>
                                    ))
                                }
                                {
                                    this.state.drafts.map((el, index) => (
                                        <div key={index} className={`data-row ${this.state.viewType}`}>
                                            {this.state.viewType === 'list' && 
                                                <div className="flex-container vertical-middle">
                                                    <div className="flexbox icon-name">
                                                        <div className="file-icon">
                                                            <img 
                                                                src={commonFunction.getIcon(el.format)}
                                                                className="icon"
                                                                alt="template type"
                                                            />
                                                        </div>
                                                        <div className="details text-left">
                                                            <span className="name" title={el.name}>{el.name}</span>
                                                            <span className="date-size text-uppercase">{el.count} markers</span>
                                                        </div>
                                                    </div>
                                                    <div className={`flexbox buttons${el.showMenu ? ' open' : ''}`}>
                                                        <button className="btn" onClick={() => this.openDocumentGeneration(el)}>Update</button>
                                                        <button className="btn" onClick={() => this.setState({showDeleteConfirm: true, selectedDraftIndex: index})}>Delete</button>
                                                        <div className="relative">
                                                            <button className="btn bullet" onClick={() => this.toggleMenu(index)}>
                                                                <span>&bull;&bull;&bull;</span>
                                                            </button>
                                                            <div className="actions">
                                                                <ul>
                                                                    <li onClick={() => this.toggleMenu(index, () => { this.generateUrl(el) })}>Get public form url</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.viewType === 'grid' && <div>
                                                <div className="header draft relative">
                                                    <div className="icon-name">
                                                        <div className="file-icon">
                                                            <img 
                                                                src={commonFunction.getIcon(el.format)} 
                                                                className="icon" 
                                                                alt="template type"
                                                            />
                                                        </div>

                                                        <div className={`buttons draft${el.showMenu ? ' open' : ''}`}>
                                                            <button className="btn bullet" onClick={() => this.toggleMenu(index)}>
                                                                <span>&bull;&bull;&bull;</span>
                                                            </button>
                                                            <div className="actions">
                                                                <ul>
                                                                    <li onClick={() => this.toggleMenu(index, () => { this.generateUrl(el) })}>Get public form url</li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="details text-left">
                                                            <span className="name" title={el.name}>{el.name}</span>
                                                            <span className="date-size text-uppercase">{el.count} markers</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="icon-name"></div>
                                                <div className="button-container">
                                                    <button className="btn" onClick={() => this.openDocumentGeneration(el)}>Update</button>
                                                    <button className="btn" onClick={() => this.setState({showDeleteConfirm: true, selectedDraftIndex: index})}>Delete</button>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.selectedTemplateId && <DocumentGenerator tid={this.state.selectedTemplateId} callback={this.setInput} type="input" fdata={this.state.markerData} submit={this.generateDocument} draft={this.state.drafts[this.state.selectedDraftIndex]} folder={this.state.folderID}></DocumentGenerator>} 
                    {this.state.showDeleteConfirm && 
                        <DeleteConfirmModal
                            disabeStatus={false}
                            desc={"This will delete the draft you have created"}
                            buttonText="Delete draft"
                            close={() => {
                                this.setState({
                                    showDeleteConfirm: false, 
                                    selectedDraftIndex: undefined
                                })
                            }}
                            doDelete={this.deleteDraft}
                        />
                    }
                </main>
                <div id="loading-anchor" className="load-anchor text-center">
                    {this.state.isLoading && 
                        <div className="loading vertical-middle">
                            <span className="loading-text">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Drafts;