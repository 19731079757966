import React, {useState, useEffect, Fragment} from 'react';

import Head from "../../components/head";
import { config } from '../../components/constant';
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import FileUpload from "../../components/dashboard/file-upload";
import {commonFunction} from "../../components/common-functions";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import CloudStorage from "../../components/dashboard/integrations/cloud-storage";

import add from "../../static/svg/add.svg";
import dltBtn from "../../static/svg/delete.svg";

import {useContextData} from "../../store/ContextData";
import { RequestHook } from "../../components/hookRequest";

const Paragraph = () => {
  const {nanobar} = useContextData();
  const {API_URL, TOKEN_KEY} = config;
  const token = localStorage.getItem(TOKEN_KEY);

  const [paragraph, setParagraph] = useState([]);
  const [fileInput, setFileInput] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const [paragraphName, setParagraphName] = useState("");
  const [createNewPara, setCreateNewPara] = useState(false);
  const [selectedPara, setSelectedPara] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { 
    loading: getParagraphLoading, 
    data: getParagraphData, 
    callAPI: getParagraphCallAPI
  } = RequestHook(`api/v1/paragraph/`);
  useEffect(() => {
    if(!getParagraphLoading && getParagraphData){
      setParagraph(getParagraphData.paragraphs);
    }
  }, [getParagraphLoading, getParagraphData]);

  const deleteParaAPILink = `api/v1/paragraph/${selectedPara._id}/`
  const { 
    loading: deleteParagraphLoading, 
    data: deleteParagraphData, 
    callAPI: deleteParagraphCallAPI
  } = RequestHook(deleteParaAPILink, false, {}, "DELETE");
  useEffect(() => {
    if(!deleteParagraphLoading && deleteParagraphData){
      setSelectedPara({});
      setShowDeleteConfirm(false);
      commonFunction.showAlert(`One paragraph has been successfully deleted!`);
      getParagraphCallAPI();
    }
  }, [deleteParagraphLoading, deleteParagraphData, getParagraphCallAPI]);

  const fileUploadCallback = () => {
    commonFunction.showAlert(`Nice! ${paragraphName} paragraph has been successfully created.`);
    setCreateNewPara(false);
    setParagraphName("");
    setTimeout(() => {
      getParagraphCallAPI();
    });
  };

  const downloadPara = (el) => {
    nanobar.current.classList.add('running');
    nanobar.current.setAttribute('data-state', 'running');
    fetch(`${API_URL}api/v1/document/download/${el._id}?access_token=${token}`)
    .then(resp => resp.blob())
    .then(blob => {
      if(
        nanobar.current.classList.contains('running') && 
        nanobar.current.getAttribute('data-state') === 'running'
      ){ 
        nanobar.current.setAttribute('data-state', 'paused');
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.style.display = 'none';
      a.href = url;
      a.download = el.filename;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('oh no!'));
  };


  const cloudStorageDataCallback = (platform, data, otherData) => {
    nanobar.current.classList.add('running');
    nanobar.current.setAttribute('data-state', 'running');
    setShowLoading(true);
    const {name, file} = otherData;
    fetch(data)
    .then((resp) => resp.arrayBuffer())
    .then((blob) => {
      if(
        nanobar.current.classList.contains('running') && 
        nanobar.current.getAttribute('data-state') === 'running'
      ){ 
        nanobar.current.setAttribute('data-state', 'paused');
      }

      const {mimeType: type} = file;
      const createdFile = new File([blob], name, {type});
      setFileInput(createdFile);
      setShowLoading(false);
    })
    .catch(() => {
      commonFunction.showAlert("Unable to download the file!", 'error');
    });
  };

  return (
    <Fragment>
      <Head customTitle={"Paragraph"}/>
      <Header></Header>
      <SideNav index={4}></SideNav>
        <main className="dashboard-container template">
          <div className="main-container">
            <div className="body">
              <div className="flex-container">
                <div className="flexbox two-third left">
                  <div className="row-heading text-uppercase">
                    <div className="flex-container vertical-middle">
                      <div className="flexbox select-all">
                        {/* <span>Image</span> */}
                      </div>
                      <div className="flexbox name">
                        <span>Name</span>
                      </div>
                      <div className="flexbox view-type text-right">
                        Action
                      </div>
                    </div>
                  </div>
                  <div className="list-container">
                  {
                    paragraph.map((el, index) => (
                      <div key={index} className="data-row list">
                        <div className="flex-container vertical-middle">
                          <div className="flexbox icon-name">
                            <div className="file-icon">
                              <img 
                                src={commonFunction.getIcon(`${el.name}.txt`)}
                                className="icon"
                                alt="template type"
                              />
                            </div>
                            <div className="details text-left">
                              <span className="name" title={el.filename}>{el.filename}</span>
                              <span className="date-size text-uppercase">
                                {el.addedAt && 
                                  <span className="text-uppercase">{commonFunction.getDateFormat(el.addedAt)}</span>
                                }
                              </span>
                            </div>
                          </div>
                          <div className="flexbox buttons">
                            <button
                              className="btn bullet"
                              onClick={() => downloadPara(el)}
                            >Download</button>
                            <button
                              className="btn bullet"
                              onClick={() => {
                                commonFunction.copyText(el._id);
                                commonFunction.showAlert(
                                  `The paragraph id has been successfully copied.`
                                );
                              }}
                            >Copy ID</button>
                            <button
                              className="btn bullet"
                              onClick={() => {
                                setSelectedPara(el);
                                setShowDeleteConfirm(true);
                              }}
                            >Delete</button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  </div>
                </div>
                <div className="flexbox one-third right">
                  <button
                    type="button"
                    className="btn with-image moral full"
                    onClick={() => setCreateNewPara(true)}
                  >
                    <img src={add} className="btn-img" alt="icon"/>
                    Upload a new paragraph
                  </button>
                </div>
              </div>
            </div>
          </div>
            
          {createNewPara && 
            <div className="full-page-overlay">
              <div className="flex-container full-height vertical-middle">
                <div className="template-uploader">
                  <div className="modal-body relative">
                    <div className="heading">Upload a new paragraph</div>
                    <div className="close" onClick={() => setCreateNewPara(false)}>
                      <img src={dltBtn} alt="close"/>
                    </div>
                    <p className="desc">Drag and drop or click to select your DOCX file</p>
                    <div className="form-group has-field">
                      <label htmlFor="para-body">Paragraph file<span>*</span></label>
                      <FileUpload
                        file={fileInput}
                        link={`${API_URL}api/v1/paragraph/upload/`}
                        skey="paragraphFile"
                        callback={fileUploadCallback}
                      />

                      <div className="or-separator"/>

                      <CloudStorage
                        callback={cloudStorageDataCallback}
                        data={{
                          platform: "",
                          data: ""
                        }}
                        fileSelect={true}
                        acceptedMime={[
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ]}
                      />
                    </div>
                    {showLoading && 
                      <div className="loading-screen">
                        Downloading file from cloud...
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          {showDeleteConfirm && 
            <DeleteConfirmModal
              disabeStatus={deleteParagraphLoading}
              desc={"This will delete the paragraph you have uploaded"}
              buttonText="Delete paragraph"
              close={() => {
                setShowDeleteConfirm(false);
                setSelectedPara({});
              }}
              doDelete={deleteParagraphCallAPI}
            />
          }
        </main>    
    </Fragment>
  );
};

export default Paragraph;
