import React, {useState, useEffect, Fragment} from 'react';
import { RequestHook } from "../hookRequest";
import EmailDistribution from "./email-distribution";
import DocuSignDistribution from "./integrations/docusign-distribution";
import CloudStorageDistribution from "./integrations/cloud-storage-distribution";

const Distribution = (props) => {
    const {type, data={}, callback, draft, submit} = props;

    const {tab="email"} = data;
    const [activeTab, setActiveTab] = useState(tab);
    const [availPlatform, setAvailPlatform] = useState([]);
    const [disableFinishButton, setDisabledFinishButton] = useState(false);

    useEffect(() => {
        setDisabledFinishButton(false);
    }, [activeTab]);
    
    const integration = `api/v1/integrations/`;
    const { 
        loading: inteLoading, 
        data: inteData
    } = RequestHook(integration);
    useEffect(() => {
        if(!inteLoading && inteData){
            const {data} = inteData;
            setAvailPlatform(data);
        }
    }, [inteLoading, inteData]);

    return (
        <Fragment>
            <div className="form-container">
                <div className="tab-container">
                    <div className="tab-heading">
                        <div
                            className={`tabs text-uppercase${activeTab === 'email' ? ' active' : ''}`}
                            onClick={() => setActiveTab("email")}
                        >Email</div>
                        <div
                            className={`tabs text-uppercase${activeTab === 'cloud-storage' ? ' active' : ''}`}
                            onClick={() => setActiveTab('cloud-storage')}
                        >Cloud storage</div>
                        <div
                            className={`tabs text-uppercase${activeTab === 'sign-document' ? ' active' : ''}`}
                            onClick={() => setActiveTab('sign-document')}
                        >Sign document</div>
                    </div>

                    {activeTab === 'email' && 
                        <EmailDistribution
                            {...props}
                            tab={activeTab}
                        />
                    }
                    {activeTab === 'cloud-storage' && 
                        <CloudStorageDistribution
                            {...props}
                            tab={activeTab}
                            disable={setDisabledFinishButton}
                            availPlatform={availPlatform}
                        />
                    }
                    {activeTab === 'sign-document' && 
                        <DocuSignDistribution
                            {...props}
                            tab={activeTab}
                            disable={setDisabledFinishButton}
                            availPlatform={availPlatform}
                        />
                    }
                </div>
            </div>
            <div className="footer text-right">
                <button 
                    className="btn" 
                    onClick={() => callback('stepNumber', (
                        (type === 'upload' || type === 'database' || type === 'crm') ? 2 : 1)
                    )}
                >Back</button>
                <button
                    className="btn green"
                    disabled={disableFinishButton}
                    onClick={() => {
                        setDisabledFinishButton(true);
                        draft();
                    }}
                >Save Draft</button>
                <button
                    className="btn moral"
                    disabled={disableFinishButton}
                    onClick={() => {
                        setDisabledFinishButton(true);
                        submit();
                    }}
                >Finish</button>
            </div>
        </Fragment>
    )
};

export default Distribution;