import React, {useState, useEffect, Fragment} from 'react';

import { config } from '../../components/constant';
import {commonFunction} from "../../components/common-functions";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import FileUpload from "../../components/dashboard/file-upload";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import CloudStorage from "../../components/dashboard/integrations/cloud-storage";

import add from "../../static/svg/add.svg";
import dltBtn from "../../static/svg/delete.svg";

import {useContextData} from "../../store/ContextData";
import { RequestHook } from "../../components/hookRequest";

const Image = () => {
  const {nanobar} = useContextData();
  const {API_URL, TOKEN_KEY} = config;
  const token = localStorage.getItem(TOKEN_KEY);

  const [images, setImages] = useState([]);
  const [fileInput, setFileInput] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  const { 
    loading: getImageLoading, 
    data: getImageData, 
    callAPI: getImageCallAPI
  } = RequestHook(`api/v1/image`);
  useEffect(() => {
    if(!getImageLoading && getImageData){
      setImages(getImageData.images);
    }
  }, [getImageLoading, getImageData]);

  const deleteImageAPILink = `api/v1/image/${selectedImage._id}/`
  const { 
    loading: deleteImageLoading, 
    data: deleteImageData, 
    callAPI: deleteImageCallAPI
  } = RequestHook(deleteImageAPILink, false, {}, "DELETE");
  useEffect(() => {
    if(!deleteImageLoading && deleteImageData){
      setSelectedImage({});
      setShowDeleteConfirm(false);
      commonFunction.showAlert(`One image has been deleted successfully.`);
      getImageCallAPI();
    }
  }, [deleteImageLoading, deleteImageData, getImageCallAPI]);

  const callback = () => {
    setUploadImage(false);
    commonFunction.showAlert(`Nice! One image has been successfully uploaded.`);
    getImageCallAPI();
  };

  const cloudStorageDataCallback = (platform, data, otherData) => {
    nanobar.current.classList.add('running');
    nanobar.current.setAttribute('data-state', 'running');
    setShowLoading(true);
    const {name, file} = otherData;
    fetch(data)
    .then((resp) => resp.arrayBuffer())
    .then((blob) => {
      if(
        nanobar.current.classList.contains('running') && 
        nanobar.current.getAttribute('data-state') === 'running'
      ){ 
        nanobar.current.setAttribute('data-state', 'paused');
      }

      const {mimeType: type} = file;
      const createdFile = new File([blob], name, {type});
      setFileInput(createdFile);
      setShowLoading(false);
    })
    .catch(() => {
      commonFunction.showAlert("Unable to download the file!", 'error');
    });
  };

  return (
    <Fragment>
      <Head customTitle={"Images"}/>
      <Header></Header>
      <SideNav index={3}></SideNav>
        <main className="dashboard-container template">
          <div className="main-container">
            <div className="body">
              <div className="flex-container">
                <div className="flexbox two-third left">
                  <div className="row-heading text-uppercase">
                    <div className="flex-container vertical-middle">
                      <div className="flexbox select-all">
                        <span>Image</span>
                      </div>
                      <div className="flexbox name">
                        <span>Name</span>
                      </div>
                      <div className="flexbox view-type text-right">
                        Action
                      </div>
                    </div>
                  </div>
                  <div className="list-container">
                    {
                      images.map((el, index) => (
                        <div key={index} className="data-row list">
                          <div className="flex-container vertical-middle">
                            <div className="flexbox icon-name">
                              <div className="file-icon">
                                <img
                                  src={`${API_URL}api/v1/image/download/${el._id}?access_token=${token}`}
                                  className="icon"
                                  alt="template type"
                                />
                              </div>
                              <div className="details text-left">
                                <span className="name" title={el.filename}>{el.filename}</span>
                                <span className="date-size text-uppercase">
                                  <span className="text-uppercase">{commonFunction.getDateFormat(el.addedAt)}</span>
                                  <span className="separator">-</span>
                                  <span className="text-uppercase">{commonFunction.getFileSize(el.size)}</span>
                                </span>
                              </div>
                            </div>
                            <div className="flexbox buttons">
                              <button
                                className="btn bullet"
                                onClick={() => {
                                  commonFunction.copyText(el._id);
                                  commonFunction.showAlert(
                                    `The image id has been successfully copied.`
                                  );
                                }}
                              >Copy ID</button>
                              <button
                                className="btn bullet"
                                onClick={() => setSelectedImage(el)}
                              >View</button>
                              <button
                                className="btn bullet"
                                onClick={() => {
                                  setSelectedImage(el);
                                  setShowDeleteConfirm(true);
                                }}
                              >Delete</button>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="flexbox one-third right">
                  <button
                    type="button"
                    className="btn with-image moral full"
                    onClick={() => setUploadImage(true)}
                  >
                    <img src={add} className="btn-img" alt="icon"/>
                    Upload a new image
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          {Object.keys(selectedImage).length > 0 && !showDeleteConfirm && 
            <div className="full-page-overlay">
              <div className="flex-container full-height vertical-middle">
                <div className="template-uploader">
                  <div className="modal-body relative">
                    <div className="heading">View image</div>
                    <div className="close" onClick={() => {
                      setSelectedImage({});
                    }}>
                      <img src={dltBtn} alt="close"/>
                    </div>
                    <img 
                      src={`${API_URL}api/v1/image/download/${selectedImage._id}?access_token=${token}`}
                      alt="view upload"
                      className="max-width"
                    />
                  </div>
                </div>
              </div>
            </div>
          }

          {uploadImage && 
            <div className="full-page-overlay">
              <div className="flex-container full-height vertical-middle">
                <div className="template-uploader relative">
                  <div className="modal-body relative">
                    <div className="heading">Upload a image</div>
                    <div className="close" onClick={() => setUploadImage(false)}>
                      <img src={dltBtn} alt="close"/>
                    </div>
                    <p 
                      className="desc"
                    >Please upload a images which can be used later while creating the document.</p>

                    <FileUpload
                      file={fileInput}
                      link={`${config.API_URL}api/v1/image/`}
                      skey="image"
                      callback={callback}
                      acf="image/*"
                    />

                    <div className="or-separator"/>

                    <CloudStorage
                      callback={cloudStorageDataCallback}
                      data={{
                        platform: "",
                        data: ""
                      }}
                      fileSelect={true}
                      acceptedMime={[
                        "image/"
                      ]}
                    />
                  </div>
                  {showLoading && 
                    <div className="loading-screen">
                      Downloading file from cloud...
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {showDeleteConfirm && 
            <DeleteConfirmModal
              disabeStatus={deleteImageLoading}
              desc={"This will delete the image you have uploaded"}
              buttonText="Delete image"
              close={() => {
                setShowDeleteConfirm(false);
                setSelectedImage({});
              }}
              doDelete={deleteImageCallAPI}
            />
          }
        </main>
    </Fragment>
  );
};

export default Image;