import React, {useState, useEffect, useRef} from 'react';
import { Link } from "react-router-dom";
import Logo from "../../static/images/logo.png";
import { commonFunction } from '../common-functions';
import {useContextData} from "../../store/ContextData";
require("../../static/css/header.css");

const Header = () => {
    const [isDropDownOpened, setisDropDownOpened] = useState(false);
    const { email, name, firstCharacter } = useContextData();
    const elm = useRef();

    const outSideClick = (e) => {
        if(e && e.path && e.path.length > 0){
            let flag = true;
            e.path.forEach((p) => {
                if(p === elm.current){
                    flag = false;
                    return flag;
                }
            });
            setisDropDownOpened(!flag);
        }
    };

    useEffect(() => {
        if(isDropDownOpened) commonFunction.bindEvent(document, 'click', outSideClick);
        return () => {
            commonFunction.unbindEvent(document, 'click', outSideClick);
        }
    }, [isDropDownOpened])

    
    return (
        <header>
            <div className="flex-container full-height vertical-middle">
                <div className="flexbox logo">
                    <Link to="/" className="company-logo">
                        <img src={Logo} className="logo" alt="company logo"/>
                    </Link>
                </div>
                <div className="flexbox user" ref={elm}>
                    <div className={(isDropDownOpened) ? 'user-details open' : 'user-details'}>
                        <div 
                            className="details-holder flex-container vertical-middle"
                            onClick={() => setisDropDownOpened(!isDropDownOpened)}
                        >
                            <div className="image-holder flexbox">
                                <span className="dot text-uppercase text-center">{firstCharacter}</span>
                            </div>
                            <div className="name-email-holder flexbox">
                                <div className="name">{name}</div>
                                <div className="email">{email}</div>
                            </div>
                        </div>
                        
                        <ul className="dropdown-menu" role="menu">
                            <li>
                                <Link to="/profile/"> Profile</Link>
                            </li>
                            <li>
                                <Link to="/logout/"> Log Out</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
