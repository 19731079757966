import React, {Component} from 'react';
import axios from 'axios';
import Alert from 'react-s-alert';

import { config } from '../../components/constant';
import {commonFunction} from "../../components/common-functions";
import Head from "../../components/head";
import Header from "../../components/dashboard/header";

import profilePic from "../../static/images/profile-img.png";
import dltBtn from "../../static/svg/delete.svg";

class Profile extends Component{
    constructor(props){
        super(props);
        this.state = {
          user: {
            name: "XXX",
            email: "xx@xx.com",
            createdOn: 0,
          },
          showDeleteConfirm: false,
          tab: "sendgrid",
          enableSaveButton: false,
          formData: {
            sendgrid: {
              mail_from: "",
              sendgrid_api_key: "",
              subject: "",
              body: "",
            },
            office365: {
              host: "",
              port: "",
              user: "",
              password: "",
              subject: "",
              body: "",
            },
            gsuite: {
              host: "",
              port: "",
              user: "",
              service_client: "",
              key: "",
              subject: "",
              body: "",
            },
            awsses: {
              mail_from: "",
              aws_access_key_id: "",
              aws_access_key: "",
              apiversion: "",
              region: "",
              subject: "",
              body: "",
            },
          },
          labelObject: {
            mail_from: "Mail from",
            sendgrid_api_key: "Sendgrid API Key",
            subject: "Mail subject",
            body: "Mail body",
            user: "User",
            password: "Password",
            service_client: "Service client",
            host: "Host name",
            port: "Port number",
            key: "Key",
            aws_access_key_id: "AWS Access Key ID",
            aws_access_key: "AWS Access Key",
            apiversion: "API Version",
            region: "Region"
          },
        };
    };

    updateFormInput(type, key, value){
        var tempObject = {...this.state.formData},
            validity,
            validObject;
        tempObject[type][key] = value;

        validObject = {...tempObject[type]};
        delete validObject['body'];
        delete validObject['subject'];
        validity = commonFunction.isObjectValid(validObject);
        this.setState({
            formData: tempObject,
            enableSaveButton: (validity.flag === validity.key)
        });
    }

    changeTab(tab){
        var validity,
        validObject = {...this.state.formData[tab]}; 

        delete validObject['body'];
        delete validObject['subject'];
        validity = commonFunction.isObjectValid(validObject);

        this.setState({
            tab: tab,
            enableSaveButton: (validity.flag === validity.key)
        });
    }

    saveFormData(){
        var tempObject = {...this.state.formData[this.state.tab]};
        tempObject['type'] = this.state.tab;
        axios.post(`${config.API_URL}api/v1/user/mail/`, tempObject)
        .then(data => {
            if(data.status === 200){
                Alert.success(`Nice! email preference have been saved successfully.`, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
            }
        })
    }

    deleteProfile(){
        axios.delete(`${config.API_URL}api/v1/user`)
        .then(() => {
            window.location.href = `/logout/`;
        });
    };

    deleteEmail(){
        axios.delete(`${config.API_URL}api/v1/user/mail`)
        .then(() => {
            Alert.success(`Okay! email data has been deleted successfully.`, {
                position: 'bottom-right',
                effect: 'slide',
                timeout: 5000
            });
            
            var tab = this.state.tab,
                tempObject = {...this.state.formData};

            Object.keys(tempObject[tab]).forEach((key) => {
                tempObject[tab][key] = "";
            });
            
            this.setState({
                formData: tempObject,
                enableSaveButton: false
            });
        });
    };

    componentDidMount(){
        axios.all([
            axios.get(`${config.API_URL}api/v1/user/`),
            axios.get(`${config.API_URL}api/v1/user/mail/`)
        ])
        .then(axios.spread((user, mail) => {
            var tab = this.state.tab,
                tempObject = {...this.state.formData},
                validity,
                validObject;

            if(mail.data && mail.data.message){
                tab = mail.data.message.type;
                Object.keys(tempObject[tab]).forEach((key) => {
                    if(key === 'user' || key === 'password') tempObject[tab][key] = mail.data.message.auth[key];
                    else if(key === 'key') tempObject[tab][key] = mail.data.message.auth.privateKey;
                    else if (key === "service_client")
                      tempObject[tab][key] = mail.data.message.auth.serviceClient;
                   else if (key === "aws_access_key_id")
                      tempObject[tab][key] = mail.data.message.auth.aws_access_key_id;
                    else if (key === "aws_access_key")
                      tempObject[tab][key] = mail.data.message.auth.aws_access_key;
                    else if (key === "apiversion")
                      tempObject[tab][key] = mail.data.message.auth.apiversion;
                    else if (key === "region")
                      tempObject[tab][key] = mail.data.message.auth.region;
                    else if (key === "mailsendingrate")
                      tempObject[tab][key] = mail.data.message.auth.mailsendingrate;
                    else tempObject[tab][key] = mail.data.message[key];
                });
            }

            validObject = {...tempObject[tab]};
            delete validObject['body'];
            delete validObject['subject'];
            validity = commonFunction.isObjectValid(validObject);

            this.setState({
                user: user.data.user,
                tab: tab,
                formData: tempObject,
                enableSaveButton: (validity.flag === validity.key)
            });
        }));
    }

    render(){
        return (
            <div>
                <Head customTitle={"Profile"}/>
                <Header></Header>
                <main className="dashboard-container profile">
                    <div className="main-container">
                        <div className="flex-container">
                            <div className="flexbox image-cont">
                                <img src={profilePic} className="image" alt="profile"/>
                            </div>
                            <div className="flexbox details-cont">
                                <h1 className="name">{this.state.user.name}</h1>
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <p>{this.state.user.email}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Joined on</label>
                                    <p>{new Date(this.state.user.createdOn).toString()}</p>
                                </div>
                                <button className="btn moral" onClick={() => this.setState({showDeleteConfirm: true})}>Delete my account</button>
                            </div>
                        </div>

                        <div className="mail-setup tab-container">
                            <div className="tab-heading">
                                <div className={`tabs text-uppercase${this.state.tab === 'sendgrid' ? ' active' : ''}`} onClick={() => this.changeTab('sendgrid')}>Sendgrid</div>
                                <div className={`tabs text-uppercase${this.state.tab === 'office365' ? ' active' : ''}`} onClick={() => this.changeTab('office365')}>Office 365</div>
                                <div className={`tabs text-uppercase${this.state.tab === 'gsuite' ? ' active' : ''}`} onClick={() => this.changeTab('gsuite')}>GSuite</div>
                                <div className={`tabs text-uppercase${this.state.tab === 'awsses' ? ' active' : ''}`} onClick={() => this.changeTab('awsses')}>AWS SES</div>
                            </div>
                            
                            {
                                Object.keys(this.state.formData).map(key => (
                                    <div key={key}>
                                        {this.state.tab === key && 
                                            <div className="tab-body">
                                                {
                                                    Object.keys(this.state.formData[key]).map(fkey => (
                                                        <div className="form-group" key={key+fkey}>
                                                            <label htmlFor={key+fkey}>
                                                                {this.state.labelObject[fkey] || fkey}{(fkey !== 'body' && fkey !== 'subject') && <span>*</span>}
                                                            </label>
                                                            {fkey !== 'body' && <input type={fkey === 'password' ? "password" : "text"} id={key+fkey} className="input-field" required={fkey !== 'subject' ? true : false} placeholder={`Write down ${this.state.labelObject[fkey].toLowerCase()}`} onChange={(e) => this.updateFormInput(key, fkey, e.target.value)} value={this.state.formData[key][fkey]}/>}
                                                            {fkey === 'body' && <textarea className="input-field" placeholder={`Write down ${this.state.labelObject[fkey].toLowerCase()}`} id={key+fkey} onChange={(e) => this.updateFormInput(key, fkey, e.target.value)} defaultValue={this.state.formData[key][fkey]}></textarea>}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                            <div className="button-holder text-left">
                                <button className="btn moral" disabled={!this.state.enableSaveButton} onClick={() => this.saveFormData()}>Save data</button>
                                <button className="btn green" disabled={!this.state.enableSaveButton} onClick={() => this.deleteEmail()}>Delete</button>
                            </div>
                        </div>
                    </div>

                    {this.state.showDeleteConfirm && 
                        <div className="full-page-overlay">
                            <div className="flex-container full-height vertical-middle">
                                <div className="template-uploader">
                                    <div className="modal-body relative">
                                        <div className="heading">Are you sure?</div>
                                        <div className="close" onClick={() => this.setState({showDeleteConfirm: false})}>
                                            <img src={dltBtn} alt="close"/>
                                        </div>
                                        <p className="desc">Once your profile is deleted there is no way you will be able to recover all your files and data.</p>
                                        <div className="footer text-right">
                                            <button className="btn" onClick={() => this.setState({showDeleteConfirm: false})}>Cancel</button>
                                            <button className="btn moral" onClick={() => this.deleteProfile()}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
            </div>
        )
    }
}

export default Profile;
