import React, {Component} from 'react';
import Head from "../components/head";
import Logo from "../static/images/logo.png";
import axios from 'axios';
import Alert from 'react-s-alert';
import { Link } from "react-router-dom";
import { config } from '../components/constant';

require('../static/css/login.css');

class ForgotPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            disabled: true
        };
    }

    setInput(e, type){
        var tempObject = {};
        tempObject[type] = e.target.value;
        this.setState(tempObject, () => {
            tempObject = {disabled: true};
            if(this.state.email) tempObject.disabled = false;
            this.setState(tempObject)
        });
    }

    forgot(e){
        e.preventDefault();
        if(this.state.email){
            let formData = new FormData();
            formData.set('email', this.state.email);

            axios({
                method: 'post',
                url: `${config.API_URL}reset/`,
                data: formData
            })
            .then(res => {
                Alert.success(res.data.message, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                this.props.history.push("/login/");
            })
            .catch(err => {
                if(err.response && err.response.data) Alert.error(err.response.data.error, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
                else Alert.error(err, {
                    position: 'bottom-right',
                    effect: 'slide',
                    timeout: 5000
                });
            })
        }
    }

    render(){
        return (
            <div>
                <Head customTitle={"Forgot password"}/>
                <section className="login-holder">
                    <div className="login-header text-center">
                        <img src={Logo} className="company-icon" alt="logo"/> 
                        <h3 className="text-uppercase">Password recovery</h3>
                        <p className="sub-text">No worries. It happens to most of us. Please enter the email address that you have used during sign-up so we can send you a password reset link.</p>
                    </div>
                    <div className="login-form">
                        <form name="loginform" onSubmit={(e) => this.forgot(e)}>
                            <div className="form-group has-field email">
                                <label htmlFor="email">Enter your registered email address<span>*</span></label>
                                <input type="email" id="email" autoFocus="autofocus" className="input-field" title="What's your registered email address?" autoComplete="off" tabIndex="2" required="required" value={this.state.email} onChange={(e) => this.setInput(e, 'email')}/>
                            </div>
                            <button type="submit" className="btn moral full" disabled={this.state.disabled}>Send reset link</button>
                            <div className="text-center link">
                                <Link to="/login/">Go back</Link>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )
    }
}

export default ForgotPassword;