import Alert from 'react-s-alert';

import txt from "../static/images/txt.png";
import pdf from "../static/images/pdf.png";
import ppt from "../static/images/ppt.png";
import word from "../static/images/word.png";
import xls from "../static/images/xls.png";
import zip from "../static/images/zip.png";
import image from "../static/images/image-icon.png";
import unrecognized from "../static/images/unrecognized.png"

export const commonFunction = {
    dateFormats: [
        "DD-MM-YYYY", "D-MM-YYYY", "D-MMM-YYYY", "DD-MMM-YYYY", "DD-MM-YY", 
        "DD-MMM-YY", "MMMM D, YYYY", "MMM D, YYYY", "dddd, MMMM D, YYYY",
        "M/D/YYYY", "M.D.YYYY", "D/M/YYYY", "DD/MM/YYYY", "D.M.YYYY", 
        "M/D/YY", "M.D.YY", "D/M/YY", "D.M.YY", "YYYY-MM-DD", "MMMM YYYY",
        "MMM YYYY", "MM/YYYY", "D", "DD", "ddd", "dddd", "M", "MM", "MMM", 
        "MMMM", "YY", "YYYY"
    ],
    datePicker: [
        "dd-MM-yyyy", "d-MM-yyyy", "d-MMM-yyyy", "dd-MMM-yyyy", "dd-MM-yy", 
        "dd-MMM-yy", "MMMM d, yyyy", "MMM d, yyyy", "dddd, MMMM d, yyyy", 
        "M/d/yyyy", "M.d.yyyy", "d/M/yyyy", "dd/MM/yyyy", "d.M.yyyy", "M/d/yy", 
        "M.d.yy", "d/M/yy", "d.M.yy", "yyyy-MM-dd", "MMMM yyyy", "MMM yyyy", "MM/yyyy", 
        "d", "dd", "ddd", "dddd", "M", "MM", "MMM", "MMMM", "yy", "yyyy"
    ],
    getFileSize: function(bytes){
        if (bytes >= 1000000000) bytes = (bytes / 1000000000).toFixed(1) + ' GB';
        else if (bytes >= 1000000) bytes = (bytes / 1000000).toFixed(1) + ' MB';
        else if (bytes >= 1000) bytes = (bytes / 1000).toFixed(1) + ' KB';
        else if (bytes > 1) bytes = bytes + ' bytes';
        else if (bytes === 1) bytes = bytes + ' byte';
        else bytes = '0 byte'
        return bytes;
    },
    getDateFormat: function(date){
        var d, day, month, year, hour, minutes, ampm,
        checkTimeAddZero = function(i) {
            if(i < 10) i = "0"+ i;
            return i;
        };
        try{
            d = new Date(date);
        }
        catch(e){
            d = new Date();
        }
        day = d.getDate();
        month = d.getMonth() + 1;
        year = d.getFullYear();
        hour = d.getHours();
        minutes = d.getMinutes();

        if(hour >= 12) ampm= "pm";
        else ampm= "am";

        if(hour === 0) hour = 12;

        return `${
            checkTimeAddZero(day)
        }/${
            checkTimeAddZero(month)
        }/${year} ${
            checkTimeAddZero(hour)
        }:${
            checkTimeAddZero(minutes)
        }${ampm}`
    },
    formatDate: function(date){
        var monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June", 
            "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ],
        day = date.getDate(),
        monthIndex = date.getMonth(),
        year = date.getFullYear(),
        nth = function(d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        };
        return `${day}${nth(day)} ${monthNames[monthIndex]}, ${year}`; 
    },
    swap: function(items, firstIndex, secondIndex){
        var temp = items[firstIndex];
        items[firstIndex] = items[secondIndex];
        items[secondIndex] = temp;
    },
    partition: function(items, left, right) {
        var pivot   = items[Math.floor((right + left) / 2)],
            i       = left,
            j       = right;
    
        while (i <= j) {
            while (items[i] < pivot) i++;
            while (items[j] > pivot) j--;            

            if (i <= j) {
                this.swap(items, i, j);
                i++;
                j--;
            }
        }
    
        return i;
    },
    quickSort: function(items, left, right) {
        var index;
        if (items.length > 1) {
            left = typeof left != "number" ? 0 : left;
            right = typeof right != "number" ? items.length - 1 : right;

            index = this.partition(items, left, right);

            if (left < index - 1) this.quickSort(items, left, index - 1);
            if (index < right) this.quickSort(items, index, right);
        }
        return items;
    },
    makeId: function(length){
        var result           = '',
            characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charactersLength = characters.length,
            i = 0;

        for(; i < length; i++) 
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        
        return result;
    },
    isObjectValid: function(object){
        var key,
            keyCounter = 0,
            flagCounter = 0;

        for(key in object){
            if(object.hasOwnProperty(key)){
                keyCounter++;
                if(Array.isArray(object[key])){
                    if(object[key].length > 0) flagCounter++;
                }
                else if(object[key]) flagCounter++;
            }
        }
        return {flag: flagCounter, key: keyCounter};
    },
    isImageField: (key) => {
        return (key.indexOf('height=') > -1 || key.indexOf('width=') > -1);
    },
    isParaField: (key) => {
        return (key.indexOf('type=”paragraph”') > -1);
    },
    getPosition: (el) => {
		var xPos = 0, yPos = 0;
		while (el) {
			if (el.tagName === "BODY") {
				// deal with browser quirks with body/window/document and page scroll
				var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
				var yScroll = el.scrollTop || document.documentElement.scrollTop;

				xPos += el.offsetLeft - xScroll + el.clientLeft;
				yPos += el.offsetTop - yScroll + el.clientTop;
			} else {
				// for all other non-BODY elements
				xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
				yPos += el.offsetTop - el.scrollTop + el.clientTop;
			}

			el = el.offsetParent;
		}
		return { x: xPos, y: yPos };
    },
    showAlert: (message, type='success', timeout=5000) => {
        Alert[type](message, {
            position: 'bottom-right',
            effect: 'slide',
            timeout
        });
    },
    copyText: (text) => {
        const input = document.createElement('input');

        input.type = 'text';
        input.setAttribute('value', text);
        (
            document.body || document.getElementsByTagName('body')[0]
        ).appendChild(input);

        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand("copy");

        input.parentNode.removeChild(input);
    },
    // Binding the different type of event based on the browser support
	// Requires element, event type to bind and the callback
    bindEvent: (el, event, fn) => {
		el.addEventListener ? el.addEventListener(event, fn, false) : el.attachEvent && el.attachEvent('on' + event, fn);
	},

	// Unbinding the different type of event based on the browser support
	// Requires element, event type to unbind and the callback
	unbindEvent: (el, event, fn) => {
		el.removeEventListener ? el.removeEventListener(event, fn) : el.detachEvent && el.detachEvent('on' + event, fn);
	},

    getIcon: (filename) => {
        if(!filename){
            return unrecognized;
        }

        const fileNameArr = filename.toLowerCase().split('.');
        const fileFormat = fileNameArr[fileNameArr.length - 1];
        switch(fileFormat) {
            case 'doc':
            case 'docx':
                return word;

            case 'xls':
            case 'xlsx':
                return xls;

            case 'pdf':
                return pdf;

            case 'ppt':
            case 'pptx':
                return ppt;

            case 'zip':
                return zip;

            case 'txt':
                return txt;

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'bmp':
            case 'svg':
                return image;

            default:
                return unrecognized;
        }
    }
}